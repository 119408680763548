import React, {
    useCallback,
} from 'react'

import useSkyNetDomainContext from 'app/shared-components/SkyNetDomain/useSkyNetDomainContext'
import RegionalGroupCreateForm from 'app/Apps/AccountManagement/Accounts/RegionalGroups/RegionalGroupCreateForm'

const RegionalGroupCreate = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useSkyNetDomainContext()

    const onSuccess = useCallback((account) => {
        addTableElement(account)
        selectDomainObject(account?.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <RegionalGroupCreateForm
            onSuccess={onSuccess}
        />
    )
}

export default RegionalGroupCreate
