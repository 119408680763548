import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    return {
        button: {
            height: 110,
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(2),
        },
        text: {
            padding: 0,
            color: theme.palette.secondary[700],
        },
        title: {
            paddingTop: theme.spacing(3),
            color: theme.palette.secondary[900],
        },
    }
})
