import React from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useTableControlsByPermissions from 'app/hooks/useTableControlsByPermissions'
import request from './AccountLanes.requests'
import tableConfig from './AccountLanes.config'
import AccountLaneCreate from './AccountLaneCreate'
import AccountLaneOverview from './AccountLaneOverview'
import AccountLaneUpdate from './AccountLaneUpdate'
import AccountLaneBulkCreate from './AccountLaneBulkCreate'
import useAccountManagementRoutes from '../hooks/useAccountManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.BulkCreate,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const AccountLanes = () => {
    const {
        Lanes,
    } = useAccountManagementRoutes()
    const createPermission = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const updatePermission = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: Lanes.Overview.route,
                Component: AccountLaneOverview,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes('overview')
                },
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: Lanes.Update.route,
                Component: AccountLaneUpdate,
            },
            permission: updatePermission,
        },
    ])

    const restrictedTableControls = useTableControlsByPermissions(tableControls, {
        [TableControlTypes.BulkCreate]: createPermission,
    })

    return (
        <SkyNetDomain
            actionComponents={{
                Create: createPermission
                    ? AccountLaneCreate
                    : undefined,
                BulkCreate: createPermission ? AccountLaneBulkCreate : undefined,
            }}
            name={request.domainName}
            sidebarTabs={tabs}
            defaultTab="overview"
        >
            <SkyNetTable
                customUrl={request.getAll}
                tableControls={restrictedTableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default AccountLanes
