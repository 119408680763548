import {
    useMemo,
} from 'react'

import {
    App, CommonRoutes, createRoutesConfig, useSkyNetNavigate,
} from 'app/SkyNetRoutes'

import useSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useSkyNetDomainRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    LoggerTabs,
} from '../Loggers.types'

export default function useLoggerRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useSkyNetDomainRoutes()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [],
            Loggers: {
                route: App.LOGGERS,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'otherLoggersNumbers',
                        required: false,
                    }],
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Documents: {
                    route: LoggerTabs.DOCUMENTS,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
