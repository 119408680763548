import React, {
    useMemo,
    useState,
} from 'react'

import SkyNetTable, {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    DeliveryServiceType,
    TransportModeRoad,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import OrdersContext from 'app/Apps/OrderManagement/Orders/CustomerTransport/OrdersContext'
import ContainerSelection from 'app/Apps/OrderManagement/Orders/Components/ContainerSelection'
import OrderNotificationsTab from 'app/Apps/OrderManagement/Orders/Components/OrderNotificationsTab'
import OrderSteps from 'app/Apps/OrderManagement/Orders/Components/OrderSteps'
import {
    OrderStatuses,
} from 'app/types/enums'

import OrderChanges from './OrderChanges'
import CustomerTransportsOverview from './CustomerTransportsOverview'
import DataUpdate from './DataUpdate'
import BillingInformation from './BillingInformation'
import Invoices from './Invoices'
import SharedPositioning from './SharedPositioning'
import PositioningSetup from './PositioningSetup'

import customerOrderConfig from './customerTransport.config'
import orderRequest from '../orders.request'
import useOrderManagementRoutes from '../../hooks/useOrderManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

type Props = {
    name?: string,
    defaultTab?: string,
    config?: SkyNetTableConfig,
}

const defaultProps = {
    name: orderRequest.dataModelName,
    config: customerOrderConfig,
    defaultTab: 'overview',
}

const CustomerTransport = ({
    name,
    config,
    defaultTab,
}: Props) => {
    const {
        CustomerOrders: CustomerOrdersRoutes,
    } = useOrderManagementRoutes()
    const [
        selectedInvoiceId,
        setSelectedInvoiceId,
    ] = useState<number>()

    const outgoingInvoiceRead: boolean = useHasPermission(['outgoing_invoice_read'])
    const orderUpdateTransport: boolean = useHasPermission(['order_update_transport'])
    const positioningSetupCondition = ({
        orderStatus,
    }: {
        orderStatus: OrderStatuses,
    }): boolean => {
        return orderStatus === OrderStatuses.NOT_STARTED
    }
    const sharedPositioningCondition = ({
        transportModeRoad,
        deliveryServiceType,
    }: {
        transportModeRoad: TransportModeRoad,
        deliveryServiceType: DeliveryServiceType,
    }): boolean => {
        return transportModeRoad === TransportModeRoad.FTL
                && deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    }
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: CustomerOrdersRoutes.Overview.route,
                label: 'Overview',
                Component: CustomerTransportsOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.Update.route,
                label: 'Data Update',
                Component: DataUpdate,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.Notifications.route,
                label: 'Notifications',
                Component: OrderNotificationsTab,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.OrderChanges.route,
                label: 'Order Changes',
                Component: OrderChanges,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.ContainerSelection.route,
                label: 'Containers / Freight',
                Component: ContainerSelection,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.OrderSteps.route,
                label: 'Order Steps',
                Component: OrderSteps,
            },
            permission: true,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.BillingInformation.route,
                label: 'Billing Information',
                Component: BillingInformation,
            },
            permission: outgoingInvoiceRead,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.Invoices.route,
                label: 'Invoices',
                Component: Invoices,
            },
            permission: outgoingInvoiceRead,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.SharedPositioning.route,
                label: 'Shared Positioning',
                Component: SharedPositioning,
                condition: sharedPositioningCondition,
            },
            permission: orderUpdateTransport,
        },
        {
            tab: {
                url: CustomerOrdersRoutes.PositioningSetup.route,
                label: 'Positioning Setup',
                Component: PositioningSetup,
                condition: positioningSetupCondition,
            },
            permission: true,
        },
    ])

    const contextValue = useMemo(() => {
        return {
            selectedInvoiceId,
            setSelectedInvoiceId,
        }
    }, [selectedInvoiceId])

    return (
        <OrdersContext.Provider value={contextValue}>
            <SkyNetDomain
                name={name}
                sidebarTabs={tabs}
                subdomainUrls={getUrlsFromTableConfig(config)}
                getDomainObject={orderRequest.OrderByNumber.requestFn}
                domainRequestKey={RequestKeys.getOrderByNumber}
                defaultTab={defaultTab}
                uniqField="orderNumber"
            >
                <SkyNetTable
                    moduleName={orderRequest.moduleName}
                    tableControls={tableControls}
                    tableConfig={config}
                    showSearch
                />
            </SkyNetDomain>
        </OrdersContext.Provider>
    )
}

CustomerTransport.defaultProps = defaultProps

export default CustomerTransport
