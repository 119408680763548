import React, {
    useMemo,
} from 'react'

import {
    Navigate,
} from 'react-router-dom'
import {
    SkyNetRouteConfigObject, useSkyNetRoutes, CommonRoutes,
} from 'app/SkyNetRoutes'
import FxRates from './FxRates'
import Invoices from './Invoices'
import useFinanceRoutes from './hooks/useFinanceRoutes'
import {
    FinanceDomains,
} from './Finance.types'

export default function Finance() {
    const Routes = useFinanceRoutes()
    const routesConfig: SkyNetRouteConfigObject<FinanceDomains> = useMemo(() => {
        return {
            [Routes.Invoices.route]: <Invoices />,
            [Routes.FxRates.route]: <FxRates />,
            [CommonRoutes.SLASH]: <Navigate to={Routes.Invoices.route} />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}
