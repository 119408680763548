import {
    useMemo,
} from 'react'
import {
    createRoutesConfig,
    SkyNetRoutesConfig,
    useSkyNetNavigate,
    App,
} from 'app/SkyNetRoutes'
import {
    TransportPlannerDomains,
} from '../TransportPlanner.types'

export default function useTransportPlannerRoutes() {
    const skyNetNavigate = useSkyNetNavigate()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.TRANSPORT_PLANNER],
            Simulation: {
                route: TransportPlannerDomains.Simulation,
                params: [{
                    name: 'id',
                    required: true,
                }],
            },
            NewSimulation: {
                route: TransportPlannerDomains.Simulation,
            },
            CO2Calculation: {
                route: TransportPlannerDomains.C02Calculation,
            },
            SavedSimulations: {
                route: TransportPlannerDomains.SavedSimulations,
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [skyNetNavigate])
}
