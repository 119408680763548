import React from 'react'
import {
    useParams,
} from 'react-router-dom'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import StatusHandler from 'app/shared-components/StatusHandler'

import config from '../transportPlaner.request'
import {
    SimulationData,
} from '../Simulation/simulation.types'
import Simulation from '../Simulation'

const SavedSimulation = () => {
    const {
        id,
    } = useParams()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<SimulationData>({
        key: RequestKeys.getSimulationById,
        params: {
            simulationId: id,
        },
        requestFunc: RequestFn.getEntity(config.SimulationById.requestFn),
        enabled: Boolean(id),
    })

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <Simulation
                data={data}
            />
        </StatusHandler>
    )
}

export default SavedSimulation
