import React, {
    useCallback, useMemo,
} from 'react'
import {
    useAuth,
} from 'app/Auth'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableResponse,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import {
    countFurtherMonths, createDefaultValues, parseForecastData,
} from '../Forecast.utils'
import getTableConfig from './ShortTermForecasts.config'
import requests from '../Forecast.requests'
import {
    ForecastOngoingOpportunities, ForecastType,
} from '../Forecast.types'
import ShortTermForecastOverview from './ShortTermForecastOverview'
import ShortTermForecastUpdate from './ShortTemForecastUpdate'
import useHasPermission from '../../../hooks/useHasPermission'
import CurrentMonthForecastUpdate from './CurrentMonthForecastUpdate'
import useForecastRoutes from '../hooks/useForecastRoutes'
import ForecastSubmission from '../ForecastSubmission'

const tableControls: TableControlTypes[] = [
    TableControlTypes.BulkEdit,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ShortTermForecasts = () => {
    const {
        ShortTermForecast: ShortTermForecastRoutes,
    } = useForecastRoutes()
    const userInfo = useAuth()
    const superUpdatePermission = useHasPermission(['forecast_super'])
    const updateRelatedPermission = useHasPermission(['forecast_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: ShortTermForecastRoutes.Overview.route,
                Component: ShortTermForecastOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: ShortTermForecastRoutes.Update.route,
                Component: ShortTermForecastUpdate,
                condition: (item: ForecastType) => {
                    return superUpdatePermission || (
                        updateRelatedPermission
                            && item?.opportunityCollaborators?.some(({
                                email,
                            }) => {
                                return email === userInfo?.user?.email
                            })
                    )
                },
            },
            permission: superUpdatePermission || updateRelatedPermission,
        },
        {
            tab: {
                label: 'Current Month Update',
                url: ShortTermForecastRoutes.CurrentMonthUpdate.route,
                Component: CurrentMonthForecastUpdate,
            },
            permission: superUpdatePermission,
        },
    ])

    const forecastFields = useMemo(() => {
        return countFurtherMonths()
    }, [])

    const tableConfig = useMemo(() => {
        return getTableConfig({
            tmpFields: Array.from(forecastFields).sort().reverse().map(([
                key,
                value,
            ]) => {
                return {
                    name: key,
                    headerProps: {
                        children: value,
                    },
                }
            }),
        })
    }, [forecastFields])

    const parseResponse = useCallback((response: TableResponse<ForecastOngoingOpportunities>) => {
        return {
            ...response,
            data: (
                (response?.data || []) as ForecastOngoingOpportunities[]
            ).reduce((acc, item) => {
                return [
                    ...acc,
                    {
                        ...item,
                        ...(item.forecasts || []).reduce(
                            parseForecastData(), createDefaultValues(forecastFields),
                        ),
                    },
                ]
            }, []),
        }
    }, [forecastFields])

    return (
        <SkyNetDomain
            name={tableConfig.name}
            sidebarTabs={tabs}
            defaultTab="overview"
            getDomainObject={requests.get}
            domainRequestKey={RequestKeys.getForecastById}
            actionComponents={{
                BulkEdit: ForecastSubmission,
            }}
        >
            <SkyNetTable
                customUrl={requests.getForecastOngoingOpportunities().url}
                tableControls={tableControls}
                tableConfig={tableConfig}
                parseResponse={parseResponse}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default ShortTermForecasts
