import {
    useMemo,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'
import {
    App,
} from 'app/types/common.enums'

import useMainMenuConfig from 'app/MainMenu/hooks/useMainMenuConfig'
import {
    ActiveTab,
} from 'app/MainMenu/MainMenu.types'

const useSelectedTab = () => {
    const {
        pathname,
    } = useLocation()
    const mainMenuConfig = useMainMenuConfig()

    const pathSplitted = pathname.split('/')

    const activeTab: ActiveTab | undefined = useMemo(() => {
        if (Object.values(App).includes(pathSplitted[2] as App)) {
            return mainMenuConfig.find(({
                id,
            }) => {
                return id === pathSplitted[2]
            })
        }

        return mainMenuConfig.find(({
            links,
        }) => {
            return links.find(({
                id,
            }) => { return id === pathSplitted[2] })
        })
    }, [
        mainMenuConfig,
        pathSplitted,
    ])

    const selectedTab: ActiveTab | {
        icon: () => null,
        links: [],
    } = useMemo(() => {
        return activeTab || {
            icon: () => { return null },
            links: [],
        }
    }, [activeTab])

    return selectedTab
}

export default useSelectedTab
