import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './Header.style'

const propTypes = {
    children: PropTypes.element.isRequired,
}

const Header = ({
    children,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={classes.buttonHeader}>
            {children}
        </div>
    )
}

Header.propTypes = propTypes

export default Header
