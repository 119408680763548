/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import {
    CommonRoutes,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import CollapsibleTable from './CollapsibleTable'
import {
    CollapsibleTableProps,
} from './CollapsibleTableProps'

export default function CollapsibleTableRouterWrapper(props: CollapsibleTableProps) {
    const {
        routeParams = [
            'actionId',
            'action',
        ],
    } = props

    return useSkyNetRoutes({
        routes: [{
            path: CommonRoutes.SLASH.concat(routeParams.map((route) => {
                return `:${route}?`
            }).join(CommonRoutes.SLASH), CommonRoutes.SLASH, CommonRoutes.ASTERISK),
            element: <CollapsibleTable {...props} />,
        }],
    })
}
