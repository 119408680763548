// eslint-disable-next-line import/prefer-default-export
export enum AccountManagementDomains {
    KAM_DASHBOARD = 'kam-dashboard',
    ACCOUNTS = 'accounts',
    REGIONAL_GROUPS = 'regional-groups',
    OPPORTUNITIES = 'opportunities',
    LANES = 'lanes',
    QUOTES = 'quotes',
    PRE_BOOKINGS = 'pre-bookings',
}
