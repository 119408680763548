import React, {
    useCallback,
    useMemo,
} from 'react'
import PropTypes from 'prop-types'

import SkyNetTable from 'app/shared-components/SkyNetTable'

import useLoggerRoutes from 'app/Apps/Loggers/hooks/useLoggerRoutes'
import requests from './ConnectedLoggers.request'
import config from './ConnectedLoggers.config'

type ConnectedLoggersFilter = {
    gatewayGatewayId: number[]
}

type ConnectedLoggersRowClick = ({
    loggerNumber: number,
}) => void

const propTypes = {
    value: PropTypes.string.isRequired,
}

const defaultProps = {}

const ConnectedLoggers = ({
    value,
}) => {
    const {
        Loggers: LoggerRoutes,
    } = useLoggerRoutes()

    const filters: ConnectedLoggersFilter = useMemo(() => {
        return {
            gatewayGatewayId: [value],
        }
    }, [value])

    const onRowClickLogger: ConnectedLoggersRowClick = useCallback(({
        loggerNumber,
    }) => {
        LoggerRoutes.Overview.navigate({
            params: {
                key: loggerNumber,
            },
        })
    }, [LoggerRoutes.Overview])

    return (
        <SkyNetTable
            name={requests.domainName}
            filter={filters}
            simple
            uniqField="loggerNumber"
            onRowClick={onRowClickLogger}
            customUrl={requests.all}
            tableConfig={{
                ...config,
                tabs: [],
            }}
        />
    )
}

ConnectedLoggers.propTypes = propTypes
ConnectedLoggers.defaultProps = defaultProps

export default ConnectedLoggers
