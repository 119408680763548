import React from 'react'
import {
    useRoutes, RouteObject,
} from 'react-router-dom'
import {
    CommonRoutes,
    SkyNetRouteConfigObject,
} from '../SkyNetRoutes.types'

/*
Hook is created to simplify the routes' configuration.
This hook is intended to add routes to React Router v6 dynamically.
For simple cases, use routesConfig as parameter
e.g.
{
    routesConfig : {
            [MasterdataDomains.COUNTRIES]: <Countries />,
            [CommonRoutes.SLASH]: <Navigate to={`./${MasterdataDomains.COUNTRIES}`} />,
     }
}
or
{
     routesConfig : {
            [MasterdataDomains.COUNTRIES]: { element: <Countries />, permission: true },
            [CommonRoutes.SLASH]: { element: <div>Not Found</>, permission: false }  ,
     }
     notFoundElement: /...some element/
}
In cases when permission is false, you can optionally pass notFoundElement as parameter to render.
In more complicated cases use
{
    routes: [{...}]
}
the same way as we add it directly to React Router's useRoutes hook.
See: https://reactrouter.com/en/main/hooks/use-routes
You can combine both of these approaches simultaneously
*/

export default function useSkyNetRoutes({
    routesConfig = {},
    routes = [],
    notFoundElement,
}: {
    routesConfig?: SkyNetRouteConfigObject<any>;
    routes?: RouteObject[];
    notFoundElement?: JSX.Element;
}) {
    return useRoutes([
        ...Object.entries(routesConfig).map(([
            path,
            element,
        ]) => {
            const resultRoute = {
                path: `${path}/${CommonRoutes.ASTERISK}`,
            }

            if ('element' in element) {
                if ('permission' in element && element.permission === false) {
                    return {
                        ...resultRoute,
                        element: notFoundElement || <div>Not Found</div>,
                    }
                }
                return {
                    ...element,
                    ...resultRoute,
                }
            }
            return {
                ...resultRoute,
                element,
            }
        }),
        ...routes,
    ] as unknown as RouteObject[])
}
