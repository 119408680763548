import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls/TableControls.types'
import useHasPermission from 'app/hooks/useHasPermission'

import useTabsByPermission from 'app/hooks/useTabsByPermission'
import CompanyOverview from './CompanyOverview'
import CompanyCreate from './CompanyCreate'
import CompanyUpdate from './CompanyUpdate'
import tableConfig from './Companies.config'
import SkyMindSync from './SkyMindSync'
import useContactManagementRoutes from '../hooks/useContactManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearSorting,
]

const Companies = () => {
    const {
        Companies: CompaniesRoutes,
    } = useContactManagementRoutes()
    const isAllowedToCreateCompany = useHasPermission(['company_create'])
    const isAllowedToUpdateCompany = useHasPermission(['company_update'])
    const isAllowedToUpdateSkyMindCompany = useHasPermission(['company_skymind_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            permission: true,
            tab: {
                url: CompaniesRoutes.Overview.route,
                label: 'Overview',
                Component: CompanyOverview,
            },
        },
        {
            permission: isAllowedToUpdateCompany,
            tab: {
                url: CompaniesRoutes.Update.route,
                label: 'Data Update',
                Component: CompanyUpdate,
            },
        },
        {
            permission: isAllowedToUpdateSkyMindCompany,
            tab: {
                url: CompaniesRoutes.SkyMind.route,
                label: 'SkyMind',
                Component: SkyMindSync,
            },
        },
    ])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: isAllowedToCreateCompany ? CompanyCreate : undefined,
            }}
            name="Company"
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default Companies
