import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ContractBasisContext,
    ContractBasisContextType,
} from '../hooks/useContractBasisContext'

import useGetContractBasis from '../hooks/useGetContractBasis'

const ContractBasisContextWrapper = ({
    id, children,
}: { id: number, children: JSX.Element }) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useGetContractBasis(id)

    const contractBasisContext = useMemo<ContractBasisContextType>(() => {
        return {
            contractId: data?.id,
            currency: data?.currency,
        }
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <ContractBasisContext.Provider value={contractBasisContext}>
                {children}
            </ContractBasisContext.Provider>
        </StatusHandler>
    )
}

export default ContractBasisContextWrapper
