import React, {
    useEffect,
} from 'react'
import {
    Outlet,
} from 'react-router-dom'
import {
    useJWTToken,
} from 'app/Auth'

import Layout from 'app/Layout'

const getEnviroment = () => {
    const url = document.location

    if (url.host.includes('pre')) {
        return ' (PRE)'
    }
    if (url.host.includes('test')) {
        return ' (TEST)'
    }
    if (url.host.includes('dev')) {
        return ' (DEV)'
    }
    return null
}

const Root = () => {
    const token = useJWTToken()

    useEffect(() => {
        const env = getEnviroment()

        if (env) {
            document.title = `SkyNet ${env}`
        }
    }, [])

    return (
        <Layout>
            <>
                {(process.env.NODE_ENV === 'development'
                || process.env.REACT_APP_TEST === 'test') && (
                    <div
                        style={{
                            display: 'none',
                        }}
                        data-testid="jwt-token"
                    >
                        {token}
                    </div>
                )}
            </>
            <Outlet />
        </Layout>
    )
}

export default Root
