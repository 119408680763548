import {
    tss,
} from 'app/tss'

export default tss.withNestedSelectors().create(({
    theme,
}) => {
    return {
        root: {
            display: 'flex',
            height: '100%',
        },
        iconContainer: {
            width: 65,
            display: 'flex',
            justifyContent: 'center',
        },
        icon: {
            width: '70%',
            height: 'auto',
        },
        iconBigger: {
            width: '100%',
        },
        iconSmaller: {
            width: '60%',
        },
        linkContainer: {
            display: 'flex',
            alignItems: 'center',
            '& :hover': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary[100],
            },
        },
        link: {
            fontWeight: 400,
            textDecoration: 'none',
            color: theme.palette.primary[30],
            padding: theme.spacing(2),
        },
        activeLink: {
            paddingTop: '19px !important',
            color: theme.palette.common.white,
            borderBottom: '3px solid white',
        },
    }
})
