import React, {
    useMemo, useCallback,
} from 'react'
import omit from 'lodash/omit'
import {
    REDIRECT_PARAM,
} from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'
import StatusHandler from 'app/shared-components/StatusHandler'
import HeaderWarning from 'app/shared-components/HeaderWarning'
import {
    useNavigate,
} from 'react-router-dom'
import useGetAccountLane from '../hooks/useGetAccountLane'
import AccountLaneForm from '../AccountLaneForm'
import request from '../AccountLanes.requests'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

const AccountLaneCopy = ({
    id,
}: {id: number}) => {
    const navigate = useNavigate()
    const {
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAccountLane(id)

    const value = useMemo(() => {
        return {
            ...omit(data, [
                'id',
                'changedBy',
                'changedOn',
            ]),
            originId: id,
        }
    }, [
        data,
        id,
    ])

    const onSuccess = useCallback(({
        id: copyId,
    }) => {
        invalidate()
        LanesRoutes.Overview.navigate({
            params: {
                key: copyId,
            },
            options: {
                state: REDIRECT_PARAM,
            },
        })
    }, [
        invalidate,
        LanesRoutes.Overview,
    ])

    const onCancel = useCallback(() => {
        navigate('..')
    }, [navigate])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <HeaderWarning>
                    You are copying a Lane.
                </HeaderWarning>
                <AccountLaneForm
                    data={value}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                    requestParam={request.create()}
                />
            </>
        </StatusHandler>
    )
}

export default AccountLaneCopy
