import React from 'react'

import {
    CommonRoutes,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import {
    Navigate,
} from 'react-router-dom'
import NewSimulation from './NewSimulation'
import SavedSimulation from './SavedSimulation'
import SavedSimulations from './SavedSimulations'
import Co2simulations from './Co2simulations'
import useTransportPlannerRoutes from './hooks/useTransportPlannerRoutes'

const TransportPlanner = () => {
    const Routes = useTransportPlannerRoutes()

    return useSkyNetRoutes({
        routes: [
            {
                path: Routes.NewSimulation.route,
                element: <NewSimulation />,
            },
            {
                path: [
                    Routes.Simulation.route,
                    Routes.Simulation.stringParams,
                ].join(CommonRoutes.SLASH),
                element: <SavedSimulation />,
            },
            {
                path: Routes.CO2Calculation.route,
                element: <Co2simulations />,
            },
            {
                path: Routes.SavedSimulations.route,
                element: <SavedSimulations />,
            },
            {
                path: CommonRoutes.SLASH,
                element: <Navigate to={Routes.NewSimulation.route} />,
            },
        ],
    })
}

export default TransportPlanner
