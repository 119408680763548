/* eslint-disable react/jsx-props-no-spreading */
import React, {
    FunctionComponent, SVGProps,
} from 'react'
import clsx from 'clsx'
import {
    Grid,
} from '@mui/material'
import {
    Link,
} from 'app/MainMenu/MainMenu.types'

import CardDashboard from 'app/shared-components/CardDashboard'
import breakpoints from 'app/Apps/Dashboard/config/breakpoints'
import Layer from 'app/Apps/Dashboard/components/Layer'
import Header from 'app/Apps/Dashboard/components/Header'
import SmallContent from 'app/Apps/Dashboard/components/SmallContent'

import useStyles from './DashboardWidget.style'

const defaultProps = {
    'data-testid': '',
    className: '',
    icon: () => {
        return ''
    },
}

export default function DashboardWidget({
    className,
    title,
    subTitleText,
    links,
    icon: Icon,
    ...rest
}: {
    title: string,
    id: string,
    subTitleText: string,
    icon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string; }>,
    className?: string,
    links: Link[],
    'data-testid'?: string
}) {
    const {
        classes,
    } = useStyles()

    if (links.length === 0) {
        return null
    }

    return (
        <Grid
            data-testid={rest['data-testid']}
            item
            {...breakpoints}
        >
            <CardDashboard
                className={clsx(className, classes.cardBody)}
                header={(
                    <Header>
                        <Icon className={classes.icon} />
                    </Header>
                )}
                smallContent={(
                    <SmallContent
                        title={title}
                        text={subTitleText}
                    />
                )}
                layer={(
                    <Layer
                        layerButtons={links}
                        layerClasses={clsx(links.length <= 3 && classes.column)}
                    />
                )}
            />
        </Grid>
    )
}

DashboardWidget.defaultProps = defaultProps
