import {
    useCallback,
    useState,
} from 'react'
import {
    useMutation,
} from '@tanstack/react-query'
import {
    useNavigate,
} from 'react-router-dom'
import {
    useJWTToken,
} from 'app/Auth'

import useHandleStatusRequest from 'app/hooks/useHandleStatusRequest'

import simpleApi from 'app/services/simpleApi'

type Params = {
    requestParamFn: (id)=> Record<string, any>,
    notificationId: string,
    title: string,
    data: Record<string, any>,
    redirectionAfterSuccess?: string,
    onSuccess?: (...args: any[]) => void
}

const useArchive = ({
    requestParamFn,
    notificationId,
    title,
    data,
    redirectionAfterSuccess,
    onSuccess,
}: Params) => {
    const token = useJWTToken()
    const navigate = useNavigate()

    // TODO-DK move this logic out of this hooks, onSuccess must belong to component, not this hook
    const onSuccessLocalSimple = useCallback(() => {
        if (onSuccess) {
            onSuccess()
        }
        if (redirectionAfterSuccess) {
            navigate(redirectionAfterSuccess)
        }
    }, [
        navigate,
        redirectionAfterSuccess,
        onSuccess,
    ])

    const {
        onSuccess: onSuccessArchive,
        onError,
    } = useHandleStatusRequest({
        id: notificationId,
        title: `${title} ${data?.archived ? 'Restored' : 'Archived'}`,
        onSuccess: onSuccessLocalSimple,
    })

    const mutation = useMutation({
        mutationFn: ({
            id,
        }: {id: number}) => {
            return simpleApi({
                ...requestParamFn({
                    id,
                }),
                token,
            })
        },
        onSuccess: onSuccessArchive,
        onError,
    })

    const handleArchiveRestore = useCallback(() => {
        setOpenConfirmDialog(true)
    }, [])

    const [
        openConfirmDialog,
        setOpenConfirmDialog,
    ] = useState(false)

    const handleArchiveRestoreConfirm = useCallback((confirm) => {
        if (confirm) {
            mutation.mutate({
                id: data.id,
            })
        }
        setOpenConfirmDialog(false)
    }, [
        mutation,
        data,
    ])

    return {
        handleArchiveRestore,
        openConfirmDialog,
        handleArchiveRestoreConfirm,
    }
}

export default useArchive
