import React from 'react'
import {
    createBrowserRouter, Navigate,
} from 'react-router-dom'
import Root from '../Root'
import {
    APPS,
} from './SkyNetRoutes.types'
import Apps from '../Apps'

const skyNetRoutes = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: `${APPS}/*`,
                element: <Apps />,
            },
            {
                path: '/',
                element: <Navigate
                    to={`/${APPS}`}
                    replace
                />,
            },
        ],
    },
    {
        path: '/',
        element: <Navigate
            to={`/${APPS}`}
            replace
        />,
    },
])

export default skyNetRoutes
