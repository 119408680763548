import React, {
    useState, useMemo,
} from 'react'
import SharedContextProvider from 'app/shared-components/SharedContext'
import FormWithControls from 'app/shared-components/FormWithControls'
import currency from 'app/utils/currency'
import {
    FeesType,
} from 'app/shared-components/Fees'
import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import {
    AdditionalFeesStatus,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequestConfig,
} from 'app/types/request.types'
import useContractBasisContext from 'app/Apps/Pricing/ContractBasis/hooks/useContractBasisContext'
import useStyles from './AdditionalFeesForm.styles'
import additionalFeesFields from './AdditionalFeesForm.fields'
import AdditionalFeesRequests from '../AdditionalFees.requests'
import {
    AdditionalFeeValue,
} from '../AdditionalFee.types'

type Props = {
    value: AdditionalFeeValue,
    onSuccess: (...a: any[]) => void,
    onCancel: (...a: any[]) => void,
    requestParams?: RequestConfig,
    customButtons?: JSX.Element[]
}

const defaultProps = {
    requestParams: undefined,
    customButtons: [],
}

export default function AdditionalFeesForm({
    value,
    onSuccess,
    onCancel,
    requestParams,
    customButtons,
}: Readonly<Props>) {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()

    const [
        modifiedValue,
        setModifiedValue,
    ] = useState(value)

    const {
        currency: contractBasisCurrency,
    } = useContractBasisContext()

    const hidefxRate = useMemo(() => {
        return contractBasisCurrency === currency.default
    }, [contractBasisCurrency])

    const exists = Boolean(value?.id)

    const fields = useFieldsWithClassName(
        additionalFeesFields(
            {
                ccy: contractBasisCurrency,
                fxRate: modifiedValue.fxRate,
                fees: value.fees,
                hidefxRate,
                exists,
                originalStatus: value.additionalFeesStatus,
                editingAllowed: value?.additionalFeesStatus
                    !== AdditionalFeesStatus.IN_PROGRESS,
                editingCommentAllowed: value.additionalFeesStatus
                    !== AdditionalFeesStatus.VALID,
                getAllowedValues,
            },
        ),
        classes,
    )

    return (
        <SharedContextProvider
            value={{
                feePricingName: FeesType.Additional,
            }}
        >
            <FormWithControls
                name={AdditionalFeesRequests.create.name}
                value={value}
                onSuccess={onSuccess}
                fields={fields}
                classNames={{
                    gridWrapper: hidefxRate
                        ? classes.gridWrapper : classes.gridWrapperWithFxRate,
                }}
                requestParams={requestParams}
                exists={exists}
                onCancel={onCancel}
                customButtons={customButtons}
                setExternalValue={setModifiedValue}
            />
        </SharedContextProvider>
    )
}

AdditionalFeesForm.defaultProps = defaultProps
