import {
    useMemo,
} from 'react'
import {
    useSkyNetNavigate,
    createRoutesConfig,
} from 'app/SkyNetRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

export enum SkyNetDomainRoutes {
    ALL = 'all',
    BULK_EDIT = 'bulk-edit',
    BULK_CREATE = 'bulk-create',
}

export enum SkyNetDomainSubRoutes {
    EDIT = 'edit',
    PRINT = 'print',
    CREATE = 'create',
}

export default function useSkyNetDomainRoutes() {
    const skyNetNavigate = useSkyNetNavigate()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [],
            All: {
                route: SkyNetDomainRoutes.ALL,
                Edit: {
                    route: SkyNetDomainSubRoutes.EDIT,
                    params: [{
                        name: 'key',
                        required: true,
                    }],
                },
                Print: {
                    route: SkyNetDomainSubRoutes.PRINT,
                },
                Create: {
                    route: SkyNetDomainSubRoutes.CREATE,
                },
            },
            BulkEdit: {
                route: SkyNetDomainRoutes.BULK_EDIT,
            },
            BulkCreate: {
                route: SkyNetDomainRoutes.BULK_CREATE,
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [skyNetNavigate])
}
