import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import request from './Users.request'

import tableConfig from './Users.config'
import UserCreate from './UserCreate'
import UserUpdate from './UserUpdate'
import useContactManagementRoutes from '../hooks/useContactManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Users = () => {
    const {
        Users: UsersRoutes,
    } = useContactManagementRoutes()
    const createPermission = useHasPermission(['user_create'])
    const readPermission = useHasPermission(['user_read'])

    const tabs: SidebarTab[] = useTabsByPermission([{
        tab: {
            label: 'Data Update',
            url: UsersRoutes.Update.route,
            Component: UserUpdate,
        },
        permission: readPermission,
    }])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: createPermission
                    ? UserCreate
                    : undefined,
            }}
            name={tableConfig.name}
            sidebarTabs={tabs}
        >
            <SkyNetTable
                moduleName={request.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                customUrl={request.all.url}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Users
