import {
    useContext, createContext,
} from 'react'
import {
    Currency,
} from 'app/types/enums'

export type ContractBasisContextType = {
    contractId: number,
    currency?: Currency,
}

export const ContractBasisContext = createContext<ContractBasisContextType>(null)

const useContractBasisContext = () => {
    return useContext<ContractBasisContextType>(ContractBasisContext)
}

export default useContractBasisContext
