import React, {
    useCallback,
} from 'react'

import useSkyNetDomainContext from 'app/shared-components/SkyNetDomain/useSkyNetDomainContext'
import AccountManagementRequests from '../../AccountManagement.request'
import AccountForm from '../AccountForm'

const CreateAccount = () => {
    const {
        selectDomainObject,
        addTableElement,
    } = useSkyNetDomainContext()

    const onSuccess = useCallback((account) => {
        addTableElement(account)
        selectDomainObject(account?.id)
    }, [
        addTableElement,
        selectDomainObject,
    ])

    return (
        <AccountForm
            onSuccess={onSuccess}
            requestParam={AccountManagementRequests.create.request()}
        />
    )
}

export default CreateAccount
