import React, {
    useMemo,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import AccountOverview from './AccountOverview'
import AccountUpdate from './AccountUpdate'
import tableConfig from './Accounts.config'
import CreateAccount from './AccountCreate'
import RegionalGroups from './RegionalGroups'
import Contacts from './Contacts'
import useAccountManagementRoutes from '../hooks/useAccountManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Accounts = () => {
    const Routes = useAccountManagementRoutes()

    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: Routes.Accounts.Overview.route,
                Component: AccountOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: Routes.Accounts.Update.route,
                Component: AccountUpdate,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Regional Groups',
                url: Routes.Accounts.RegionalGroups.route,
                Component: RegionalGroups,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Contacts',
                url: Routes.Accounts.Contacts.route,
                Component: Contacts,
            },
            permission: true,
        },
    ])

    const actionComponents = useMemo(() => {
        return {
            Create: accountCreate ? CreateAccount : undefined,
        }
    }, [accountCreate])

    return (
        <SkyNetDomain
            actionComponents={actionComponents}
            name={tableConfig.name}
            defaultTab="overview"
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default Accounts
