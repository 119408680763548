import {
    useCallback, useMemo,
} from 'react'
import {
    useNavigate, generatePath, useParams,
} from 'react-router-dom'

type Args = {
    action?: string,
    id?: string | number
}

export default (optionalRoutes: string[] = []) => {
    const navigate = useNavigate()

    const paramsMap = useMemo(() => {
        return [
            [
                optionalRoutes[0] ?? 'actionId',
                'id',
            ],
            [
                optionalRoutes[1] ?? 'action',
                'action',
            ],
        ]
    }, [optionalRoutes])

    const params = useParams()

    const updatePathWithParams = useCallback((args = {} as Args) => {
        const {
            routeParams, back, newParams,
        } = paramsMap.reduce((acc, [
            key,
            value,
        ]) => {
            if (args[value]) {
                acc.routeParams.push(`:${key}?`)
                acc.newParams[key] = args[value]
            }
            if (params[key]) {
                acc.back.push('..')
            }
            return acc
        }, {
            routeParams: [],
            back: [],
            newParams: {},
        })

        navigate(generatePath([
            ...back,
            ...routeParams,
        ].join('/'), newParams), {
            relative: 'path',
        })
    }, [
        navigate,
        params,
        paramsMap,
    ])

    const updatePathWithId = useCallback(({
        id,
    }) => {
        if (String(params?.[paramsMap[0][0]]) !== String(id)) {
            updatePathWithParams({
                id,
            })
            return
        }
        updatePathWithParams()
    }, [
        params,
        paramsMap,
        updatePathWithParams,
    ])

    return {
        updatePathWithId,
        updatePathWithParams,
        id: params?.[paramsMap[0][0]],
        action: params?.[paramsMap[1][0]],
    }
}
