import {
    RouteObject, NavigateOptions,
} from 'react-router-dom'

export type SkyNetRouteConfigObject<
    E extends string | number | symbol
> = Partial<
    Record<
        E | CommonRoutes,
        JSX.Element | (Omit<RouteObject, 'path'> & {permission?: boolean})
    >
>

export const APPS = 'apps'

export enum App {
    ACCOUNT_MANAGEMENT ='accountmanagement',
    DAMAGE_TRACKING = 'damagetracking',
    DEPLOYMENTS = 'deployments',
    FINANCE = 'finance',
    CONTACT_MANAGEMENT = 'contactmanagement',
    ORDER_MANAGEMENT = 'ordermanagement',
    MASTERDATA = 'masterdata',
    TRANSPORT_PLANNER = 'transportPlanner',
    CONTAINERS = 'containers',
    PRICING = 'pricing',
    FORECAST = 'forecast',
    RELATIONSHIPS_MANAGEMENT = 'relationshipsmanagement',
    DASHBOARD = 'dashboard',
    GATEWAYS = 'gateways',
    LOGGERS = 'loggers',
}

export enum CommonRoutes {
    SLASH = '/',
    ASTERISK = '*',
    ALL = 'all',
    EDIT = 'edit',
    DATA_UPDATE = 'data-update',
    OVERVIEW = 'overview',
    UPDATE = 'update',
    CHANGE_HISTORY = 'change-history',
    EXCLUDED = 'excluded',
}

export type SkyNetGeneratePathParams = {
    module?: App,
    domain?: string,
    domainPath?: string[],
    params?: Record<string, (string | number)>,
    searchParams?: Record<string, string>
}

export type RouteParamsConfig = {
    name: string;
    required: boolean;
};

export type NavigateFunctionArgs = {
    params?: Record<string, string | number>,
    options?: NavigateOptions
}

export type SkyNetNavigateFunction = (path: string[], p: NavigateFunctionArgs) => void

export type NavigateFunction = (a?: NavigateFunctionArgs) => void;

export type RouteDescription = {
    /* route: part of url e.g. 'address', 'edit', 'all' */
    route: string;
    /* via: an route object that leads to certain route,
    * it is not required, array will be filled with the routes and params from parents
    * however you can add this parameter if you need to add some missed parts in config structure
    * e.g. via: Locations.Update -> means using this route on the way to current
    * e.g. Locations.Update.path -> locations/all/edit/:id/data-update
    * Route: {
    *   path: ['all', 'edit', ':id']
    * }
    *
    * Locations: {
    *   route: 'locations',
    *   Update: {
    *       route: 'data-update',
    *       via: Route,
    *   }
    * }
    * 'all/edit/:id/' were added due to 'path' property
    *
    * Locations: {
    *   route: 'locations',
    *   Update: {
    *       route: 'data-update',
    *   }
    * } -> locations/data-update
    *  */
    via?: RoutesConfig;
    /*
    * params: specifies optional or required parameters of the route
    * e.g.
    * Locations: {
    *   route: 'locations',
    *   Update: {
    *       route: 'data-update',
    *       params: [{
    *           name: 'id',
    *           required: true,
    *       }]
    *   }
    * } -> locations/data-update/:id
    * */
    params?: RouteParamsConfig[];
    /*
    * navigate: function that will navigate to route it belongs to
    * you can specify custom navigate function in config
    * otherwise navigate will use path of the route
    * e.g.
    * Locations: {
    *   route: 'locations',
    *   Update: {
    *       route: 'data-update',
    *       params: [{
    *           name: 'id',
    *           required: true,
    *       }]
    *   }
    * } -> Locations.Update.navigate({params: {id: 23}}) will go to 'locations/data-update/23'
    * */
    navigate?: NavigateFunction;
};

export type RouteOutcome = {
    /*
    * path return an array of all routes leads to current route
    *
    * */
    path: string[],
    /*
    * routeParams accumulates all params we can specify for certain path
    * */
    routeParams?: string[];
    /*
    * requiredParams the same as routes, however accumulates only required to pass params
    * function navigate or generateSkyNetRoute will throw an error if it is not specified
    * */
    requiredParams?: string[];
    /*
    * stringParams - params config converted to react-router's string notation
    * e.g. [{name: 'id', required: false}] -> [Route(s)].stringParams -> ':id?'
    * e.g. [{name: 'key', required: true}] -> [Route(s)].stringParams -> ':key'
    * */
    stringParams?: string;
    /*
    * pattern: string in /path/to/certain/module/:with/:params? format
    * */
    pattern: string
}

export type RouteBaseConfig = RouteDescription & RouteOutcome

export type RoutesConfig = RouteBaseConfig & {
    [key: string]: RouteBaseConfig | RoutesConfig;
};

export type RoutesDescription = RouteDescription & {
    [key: string]: RouteDescription | RoutesDescription
}

export type SkyNetRoutesConfig = {
    [key: string]: RouteDescription | RoutesDescription | string[]
} & {
    path: string[]
};
