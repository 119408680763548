import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    SkyNetTableField,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import clsx from 'clsx'
import {
    useTheme,
} from '@mui/material/styles'
import RichTable from '@skycell-ag/richtable'

import useWindowSize from 'app/hooks/useWindowSize'
import {
    useParams, useNavigate, useOutlet, Outlet,
} from 'react-router-dom'
import {
    SkyNetDomainSubRoutes,
} from 'app/shared-components/SkyNetDomain'

import useStyles from './SkyNetTable.styles'
import {
    TableControlTypes,
} from '../TableControls'

const defaultProps = {
    columns: [],
    visibleByDefaultColumn: [],
    createForm: undefined,
    printForm: undefined,
    tableControls: undefined,
    sidebarTabs: undefined,
    onRowClick: undefined,
    selectedRows: undefined,
    onSelectRow: undefined,
    simple: false,
    filter: {},
    excludeFilters: {},
    className: '',
    counter: undefined,
    configName: undefined,
    showSelectAll: false,
    disabled: false,
    uniqField: 'id',
}

type Props = {
    name: string,
    configName: string,
    load: (params: any) => any,
    uniqField?: string,
    tableControls: TableControlTypes[],
    renderControlPanel: (controlPanelProps: Record<string, any>) => JSX.Element,
    columns: SkyNetTableField[],
    onRowClick?: (...args: any[])=>void,
    selectedRows?: number[],
    onSelectRow?: (rowIds: string|number[])=> void,
    excludeFilters?: Record<string, any>,
    filter?: Record<string, any>,
    simple?: boolean,
    richtableRef: (el: HTMLElement) => void,
    className?: string,
    visibleByDefaultColumn: SkyNetTableField[],
    showSelectAll?: boolean,
    disabled?: boolean,
}

const SkyNetTable = ({
    name,
    configName,
    load,
    uniqField,
    columns,
    onRowClick,
    selectedRows,
    onSelectRow,
    filter,
    excludeFilters,
    simple,
    richtableRef,
    className,
    visibleByDefaultColumn,
    showSelectAll,
    disabled,
    renderControlPanel,
}: Readonly<Props>) => {
    const {
        breakpoints,
    } = useTheme()

    const {
        classes,
    } = useStyles()

    const {
        key,
    } = useParams()
    const navigate = useNavigate()

    const outlet = useOutlet()

    const reducedMode = useMemo(() => {
        return (Boolean(key) || Boolean(outlet)) && !simple
    }, [
        key,
        outlet,
        simple,
    ])

    const handleOnRowClick = useCallback((row) => {
        if (selectedRows) {
            return
        }

        if (String(row[uniqField]) !== String(key)) {
            navigate(`./${SkyNetDomainSubRoutes.EDIT}/${row[uniqField]}`)
        } else {
            navigate('.')
        }
    }, [
        key,
        navigate,
        selectedRows,
        uniqField,
    ])

    const {
        width,
    } = useWindowSize()

    const visibleColumns = useMemo(() => {
        if (width < breakpoints.values.xl && (outlet && !simple)) {
            return [columns[0]]
        }
        return columns.filter(({
            reduced: visibleWhenReduced,
        }) => {
            return !reducedMode || visibleWhenReduced
        })
    }, [
        width,
        breakpoints.values.xl,
        outlet,
        simple,
        columns,
        reducedMode,
    ])

    return (
        <div
            className={clsx(classes.root, className, {
                [classes.rootResponsive]: reducedMode,
            })}
        >
            <RichTable
                ref={richtableRef}
                classNames={{
                    contentWrapper: simple ? classes.tableContentWrapper : classes.contentWrapper,
                    headerWrapper: simple ? classes.tableHeaderWrapper : '',
                }}
                noBorders={simple}
                fullWidth={simple}
                name={name}
                configName={configName}
                load={load}
                className={clsx({
                    [classes?.wrapper]: width < breakpoints.values.xl && reducedMode,
                    [classes?.tableContent]: simple,
                })}
                uniqField={uniqField}
                onRowClick={onRowClick || handleOnRowClick}
                selectedRowId={Number(key)}
                columns={visibleColumns}
                visible={visibleByDefaultColumn}
                renderControlPanel={renderControlPanel}
                selectedRows={selectedRows}
                onSelectRow={onSelectRow}
                filter={filter}
                excludeFilters={excludeFilters}
                showSelectAll={showSelectAll}
                relative={!simple}
                disabled={disabled}
            />
            <Outlet />
        </div>
    )
}

SkyNetTable.defaultProps = defaultProps

export default SkyNetTable
