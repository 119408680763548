import React, {
    useCallback,
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import DTOSpec from 'app/services/dtoSpec'
import useContactManagementRoutes from 'app/Apps/ContactManagement/hooks/useContactManagementRoutes'
import tableConfig from './GatewayContacts.config'

type Props = {
    value?: {
        id: number,
    }[],
}
type ContactsFilter = {
    id: number[]
}

const defaultProps: Partial<Props> = {
    value: [],
}

const GatewayContacts = ({
    value,
}: Props) => {
    const {
        Contacts: ContactsRoutes,
    } = useContactManagementRoutes()

    const filters: ContactsFilter = useMemo(() => {
        return {
            id: value?.map((contact) => {
                return contact.id
            }),
        }
    }, [value])

    const onRowClickContact = useCallback(({
        id,
    }) => {
        ContactsRoutes.Overview.navigate({
            params: {
                key: id,
            },
        })
    }, [ContactsRoutes])

    return (
        <DataContentWrapper
            isEmpty={isEmpty(value)}
            emptyDataReplacer="There is no data in this table yet."
        >
            <SkyNetTable
                name="Contact"
                filter={filters}
                simple
                onRowClick={onRowClickContact}
                customUrl={DTOSpec.Contact.url}
                tableConfig={tableConfig}
            />
        </DataContentWrapper>
    )
}

GatewayContacts.defaultProps = defaultProps

export default GatewayContacts
