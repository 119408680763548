import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'

import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import ContactOverview from './ContactOverview'
import ContactUpdate from './ContactUpdate'
import ContactCreate from './ContactCreate'
import getConfig from './Contacts.config'
import ContactChangeSummary from './ContactChangeSummary'
import useContactManagementRoutes from '../hooks/useContactManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Contacts = () => {
    const {
        Contacts: ContactsRoutes,
    } = useContactManagementRoutes()
    const tabs: SidebarTab[] = useMemo(() => {
        return [
            {
                url: ContactsRoutes.Overview.route,
                label: 'Overview',
                Component: ContactOverview,
            },
            {
                url: ContactsRoutes.Update.route,
                label: 'Data Update',
                Component: ContactUpdate,
            },
            {
                url: ContactsRoutes.ChangeHistory.route,
                label: 'Change History',
                Component: ContactChangeSummary,
            },
        ]
    }, [ContactsRoutes])

    const hasCreatePermissions = useHasPermissionType([], 'ContactAccessRights', 'create')

    const tableConfig = useMemo(() => {
        return getConfig()
    }, [])

    return (
        <SkyNetDomain
            name="Contact"
            sidebarTabs={tabs}
            actionComponents={{
                Create: hasCreatePermissions ? ContactCreate : undefined,
            }}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Contacts
