export enum ServiceCenterStatus {
    PLANNED = 'PLANNED',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
}

export enum ServiceCenterType {
    DRY_STORAGE_CENTER = 'DRY_STORAGE_CENTER',
    CONSIGNMENT_CENTER = 'CONSIGNMENT_CENTER',
    REPAIR_CENTER = 'REPAIR_CENTER',
    SERVICE_CENTER = 'SERVICE_CENTER',
    OVERHAUL_CENTER = 'OVERHAUL_CENTER',
    PRODUCTION_SITE = 'PRODUCTION_SITE',
}

export enum OperationalDays {
    MONDAY_FRIDAY = 'MONDAY_FRIDAY',
    MONDAY_SUNDAY = 'MONDAY_SUNDAY',
    SUNDAY_THURSDAY = 'SUNDAY_THURSDAY',
    MONDAY_SATURDAY = 'MONDAY_SATURDAY',
}

export enum StorageTypeEnum {
    C = 'C', DRY = 'DRY'
}

export enum AllowedProductTypes {
    VAL_1500C = 'VAL_1500C',
    VAL_2500C = 'VAL_2500C',
    VAL_1500XCOL = 'VAL_1500XCOL',
    VAL_1500XCOL_MONARCH = 'VAL_1500XCOL_MONARCH',
}

export type ProductMetricsType = {
    productType: AllowedProductTypes,
    minimumStoredContainers: number,
    processingRatio: number,
}

export type ContainerProductionRateType = {
    productType: AllowedProductTypes,
    dailyRate: number,
}

export type ServiceCentersTableType = {
    serviceCenterNumber: number,
    locationLocationName: string,
    serviceCenterStatus: ServiceCenterStatus,
    serviceCenterType: ServiceCenterType,
    operationalDays: OperationalDays,
    orderSelectionDelay: number,
}

export type StorageTableType = {
    id: number,
    storageCapacity: number,
    storageType: StorageTypeEnum,
    processingCapacity: number,
    processingTime: number,
}

export type ServiceCenterFormType = {
    id: number,
    serviceCenterNumber: string,
    serviceCenterStatus: ServiceCenterStatus,
    serviceCenterType: ServiceCenterType,
    operationalDays: OperationalDays,
    productMetrics: ProductMetricsType[],
    containerProductionRate: ContainerProductionRateType[],
    storages: StorageTableType[],
    serviceCenterPlannedStartDate: string,
    location: {id: number},
    enhancedSkyCenterScanning: boolean,
    orderSelectionDelay: number,
    maxTrucksPerDay: number,
    archived?: boolean,
}

export type Availability = {
    date: string,
    toBeProcessed: number,
    inProcess: number,
    preconditioned: number,
}

export type ForecastAvailability = {
    containerAvailabilityForecastForDates: Availability[],
    minPreconditioned: number,
}

export type TotalContainerAmountDry = {
    date: string,
    containerAmount: number,
}

export type DryStorage = {
    totalContainerAmountDry: TotalContainerAmountDry[],
    dryStorageCapacity: number,
}

export type TrucksAmount = {
    date: string,
    trucksAmount: number,
}

export type TruckCapacity = {
    trucksAmount: TrucksAmount[],
    maxTrucksPerDay: number,
}

export enum ServiceCenterTabs {
    Monitoring = 'monitoring'
}
