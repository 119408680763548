import React, {
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'

import Overview from './Overview'
import ChangeData from './ChangeData'

import tableConfig from './ContainerType.config'
import requests from './ContainerType.request'
import useContainerManagementRoutes from '../hooks/useContainerManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ContainerType = () => {
    const {
        ContainerTypes: ContainerTypesRoutes,
    } = useContainerManagementRoutes()
    const containerUpdate = useHasPermission(['dev_team'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: ContainerTypesRoutes.Overview.route,
                label: 'Overview',
                Component: Overview,
            },
            permission: true,
        },
        {
            tab: {
                url: ContainerTypesRoutes.Update.route,
                label: 'Change Data',
                Component: ChangeData,
            },
            permission: containerUpdate,
        },
    ])

    return (
        <SkyNetDomain
            name="ContainerTypeView"
            sidebarTabs={tabs}
            defaultTab={ContainerTypesRoutes.Overview.route}
        >
            <SkyNetTable
                moduleName={requests.name}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>
    )
}

export default ContainerType
