import React, {
    useMemo,
} from 'react'
import {
    CommonRoutes,
    SkyNetRouteConfigObject,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'

import {
    Navigate,
} from 'react-router-dom'
import Containers from './Containers'
import ContainerSelection from './ContainerSelection'
import ContainerTypes from './ContainerTypes'
import ServiceCenters from './ServiceCenters'
import useContainerManagementRoutes from './hooks/useContainerManagementRoutes'
import {
    ContainerManagementDomains,
} from './ContainerManagement.types'

const ContainerManagement = () => {
    const Routes = useContainerManagementRoutes()
    const routesConfig: SkyNetRouteConfigObject<ContainerManagementDomains> = useMemo(() => {
        return {
            [Routes.Containers.route]: <Containers />,
            [Routes.ContainersSelection.route]: <ContainerSelection />,
            [Routes.ContainerTypes.route]: <ContainerTypes />,
            [Routes.ServiceCenters.route]: <ServiceCenters />,
            [CommonRoutes.SLASH]: <Navigate to={Routes.Containers.route} />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}

export default ContainerManagement
