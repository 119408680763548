import {
    ProductType,
    TemperatureRange,
} from 'app/types/enums'
import {
    User,
} from 'app/Apps/ContactManagement/Users/Users.types'

import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'

export enum AccountTabs {
    REGIONAL_GROUPS = 'regional-groups',
    CONTACTS = 'contacts'
}

export enum AccountStatus {
    CUSTOMER = 'CUSTOMER',
    PROSPECT = 'PROSPECT'
}

export type AccountTableEntity = {
    company: Company,
    companyName: string,
    accountOwner: string,
    collaborators: string[],
    id: number,
    accountStatus: AccountStatus,
    createdOn: string,
    createdBy: { id: number },
    tempRanges: TemperatureRange[],
    annualPotentialVolume?: number,
    approvedProducts?: ProductType[],
    hasMLA?: boolean,
    hasAcquisitionPlan?: boolean,
    supportingDocuments?: {id: number}[],
    strategicAccount?: boolean,
    archived?: boolean,
}

export type Account = Omit<AccountTableEntity, 'accountOwner' | 'collaborators'> & {
    accountOwner: User,
    collaborators: User[],
}
