import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab, getUrlsFromTableConfig,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'

import useHasPermission from 'app/hooks/useHasPermission'
import GatewayRequests from './Gateways.request'
import Overview from './Overview'
import GatewayCreate from './GatewayCreate'
import GatewayUpdate from './GatewayUpdate'
import GatewayChangeHistory from './GatewayChangeHistory'
import getConfig from './Gateway.config'
import useGatewaysRoutes from './hooks/useGatewayRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Gateways = () => {
    const {
        Gateways: GatewayRoutes,
    } = useGatewaysRoutes()
    const gatewayCreate = useHasPermission(['gateway_create'])
    const gatewayUpdate = useHasPermission(['gateway_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: GatewayRoutes.Overview.route,
                label: 'Overview',
                Component: Overview,
            },
            permission: true,
        },
        {
            tab: {
                url: GatewayRoutes.Update.route,
                label: 'Data Update',
                Component: GatewayUpdate,
            },
            permission: gatewayUpdate,
        },
        {
            tab: {
                label: 'Change History',
                url: GatewayRoutes.ChangeHistory.route,
                Component: GatewayChangeHistory,
            },
            permission: true,
        },
    ])

    const tableConfig = useMemo(() => {
        return getConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: gatewayCreate ? GatewayCreate : undefined,
            }}
            name="GatewayView"
            sidebarTabs={tabs}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                moduleName={GatewayRequests.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Gateways
