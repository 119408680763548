import React from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import tableConfig from './AccountQuotes.config'
import requests from './AccountQuotes.requests'
import AccountQuoteCreate from './AccountQuoteCreate'
import AssignQuote from './AssignQuote'
import AccountQuoteOverview from './AccountQuoteOverview'
import AccountQuotePdf from './AccountQuotePdf'
import {
    AccountQuote, QuoteStatus,
} from './AccountQuotes.types'
import LanesToBeGrouped from './LanesToBeGrouped'
import AssignPricing from './AssignPricing'
import LanesToAwardReject from './LanesToAwardReject'
import AccountQuoteUpdate from './AccountQuoteUpdate'
import useAccountManagementRoutes from '../hooks/useAccountManagementRoutes'

const quoteStatusCondition = ({
    quoteStatus,
    linkedContractBasis,
}: AccountQuote = {} as AccountQuote) => {
    return Boolean(quoteStatus)
        && !(quoteStatus === QuoteStatus.CREATED
            || (quoteStatus === QuoteStatus.CANCELLED && !(linkedContractBasis?.id)))
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const defaultProps = {
    isCreateFormEnabled: true,
}

const AccountQuotes = ({
    isCreateFormEnabled,
}: {
    isCreateFormEnabled?: boolean,
}) => {
    const {
        Quotes,
    } = useAccountManagementRoutes()
    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const accountUpdate = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])
    const accountQuoteLaneGroup = useHasPermission(['account_quote_lane_group'])
    const accountQuoteLaneAwardReject = useHasPermission(['account_quote_lane_award_reject'])
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: Quotes.Overview.route,
                Component: AccountQuoteOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: Quotes.Update.route,
                Component: AccountQuoteUpdate,
            },
            permission: accountUpdate,
        },
        {
            tab: {
                label: 'Assign Quote',
                url: Quotes.AssignQuote.route,
                Component: AssignQuote,
            },
            permission: accountQuoteLaneGroup,
        },
        {
            tab: {
                label: 'Review/Group',
                url: Quotes.ReviewGroup.route,
                Component: LanesToBeGrouped,
                condition: quoteStatusCondition,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes('review-group')
                },
            },
            permission: accountQuoteLaneGroup,
        },
        {
            tab: {
                label: 'Assign Pricing',
                url: Quotes.AssignPricing.route,
                Component: AssignPricing,
                condition: quoteStatusCondition,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes('assign-pricing')
                },
            },
            permission: accountQuoteLaneGroup,
        },
        {
            tab: {
                label: 'Award/Reject',
                url: Quotes.AwardReject.route,
                Component: LanesToAwardReject,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes('award-reject')
                },
            },
            permission: accountQuoteLaneAwardReject,
        },
        {
            tab: {
                label: 'Quote PDF',
                url: Quotes.QuotePdf.route,
                Component: AccountQuotePdf,
                condition: quoteStatusCondition,
            },
            permission: true,
        },
    ])

    return (
        <SkyNetDomain
            name={tableConfig.name}
            getDomainObject={requests.get}
            sidebarTabs={tabs}
            defaultTab="overview"
            domainRequestKey={RequestKeys.getAccountQuote}
            actionComponents={{
                Create: isCreateFormEnabled && accountCreate ? AccountQuoteCreate : null,
            }}
        >
            <SkyNetTable
                customUrl={requests.getAll}
                moduleName={requests.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

AccountQuotes.defaultProps = defaultProps

export default AccountQuotes
