import React, {
    useMemo,
} from 'react'
import {
    useAuth,
} from 'app/Auth'

import useUserAvatar from 'app/hooks/useUserAvatar'

import Panel from './Panel'

export default function PanelContainer() {
    const {
        user,
    } = useAuth()

    const userName = useMemo(() => {
        if (!user) {
            return ''
        }
        const {
            firstName,
            lastName,
        } = user

        return `${firstName} ${lastName}`
    }, [user])

    const {
        data,
    } = useUserAvatar()

    return (
        <Panel
            userAvatar={data}
            userName={userName}
        />
    )
}
