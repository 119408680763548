import React, {
    useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import useHasPermissionType from 'app/Apps/ContactManagement/utils/useHasPermissionType'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import requests from './Locations.request'
import {
    ROLES_PROSPECT_ALLOWED,
} from './Locations.types'

import LocationOverview from './LocationOverview'
import LocationUpdate from './LocationUpdate'
import LocationCreate from './LocationCreate'
import LocationAreas from './LocationAreas'
import LocationChangeSummary from './LocationChangeSummary'
import getConfig from './Locations.config'
import useContactManagementRoutes from '../hooks/useContactManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Locations = () => {
    const {
        Locations: LocationsRoutes,
    } = useContactManagementRoutes()
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: LocationsRoutes.Overview.route,
                Component: LocationOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: LocationsRoutes.Update.route,
                label: 'Data Update',
                Component: LocationUpdate,
            },
            permission: true,
        },
        {
            tab: {
                url: LocationsRoutes.Areas.route,
                label: 'Areas',
                Component: LocationAreas,
            },
            permission: true,
        },
        {
            tab: {
                url: LocationsRoutes.ChangeHistory.route,
                label: 'Change History',
                Component: LocationChangeSummary,
            },
            permission: true,
        },
    ])
    const hasCreatePermissionsByMainCategory = useHasPermissionType([], 'LocationAccessRights', 'create')
    const hasCreatePermissionForProspectLocation = useHasPermission(ROLES_PROSPECT_ALLOWED)
    const hasCreatePermissions = hasCreatePermissionsByMainCategory
        || hasCreatePermissionForProspectLocation

    const tableConfig = useMemo(() => {
        return getConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: hasCreatePermissions
                    ? LocationCreate
                    : undefined,
            }}
            name="Location"
            sidebarTabs={tabs}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                moduleName={requests.domainName}
                filter={requests.filter}
                tableControls={tableControls}
                tableConfig={tableConfig}
                customUrl={requests.all().url}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Locations
