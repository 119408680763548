import React, {
    useRef,
} from 'react'
import {
    Typography,
} from '@mui/material'
import Avatar from 'app/shared-components/Avatar'

import AlertNotifications from 'app/shared-components/AlertNotifications'
import SharedContext from 'app/shared-components/SharedContext'
import ActiveTab from './ActiveTab'

import useStyles from './Panel.style'

const defaultProps = {
    userName: '',
    userAvatar: null,
}

export default function Panel({
    userName,
    userAvatar,
}: Readonly<{
    userName?: string,
    userAvatar?: string
}>) {
    const containerRef = useRef(null)
    const {
        classes,
    } = useStyles()

    return (
        <div
            className={classes.root}
            ref={containerRef}
        >
            <div>
                <ActiveTab />
            </div>
            <div
                className={classes.controls}
            >
                <SharedContext value={containerRef?.current?.offsetHeight}>
                    <AlertNotifications />
                </SharedContext>
                <div
                    className={classes.userContainer}
                >
                    <div
                        className={classes.control}
                    >
                        <Avatar img={userAvatar} />
                    </div>
                    <div
                        className={classes.control}
                    >
                        <Typography
                            data-testid="username"
                            variant="h5"
                            className={classes.userName}
                        >
                            {userName}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}

Panel.defaultProps = defaultProps
