import React, {
    useMemo,
} from 'react'

import {
    CommonRoutes, SkyNetRouteConfigObject, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import {
    Navigate,
} from 'react-router-dom'
import Damages from './Damages'
import useDamageTrackingRoutes from './hooks/useDamageTrackingRoutes'
import {
    DamageTrackingDomains,
} from './Damages.types'

export default function DamageTracking() {
    const Routes = useDamageTrackingRoutes()

    const routesConfig: SkyNetRouteConfigObject<DamageTrackingDomains> = useMemo(() => {
        return {
            [Routes.Damages.route]: <Damages />,
            [CommonRoutes.SLASH]: <Navigate to={Routes.Damages.route} />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}
