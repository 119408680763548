import {
    useMemo,
} from 'react'
import {
    App, CommonRoutes, useSkyNetNavigate,
} from 'app/SkyNetRoutes'
import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import useSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useSkyNetDomainRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    RelationshipsManagementDomains,
} from '../RelationshipsManagement.types'
import {
    EngagementTableTabs,
} from '../Engagement/engagement.types'

export default function useRelationshipsManagementRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useSkyNetDomainRoutes()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.RELATIONSHIPS_MANAGEMENT],
            ActivityRecords: {
                route: RelationshipsManagementDomains.ActivityRecords,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            Engagements: {
                route: RelationshipsManagementDomains.Engagements,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Contacts: {
                    route: EngagementTableTabs.Contacts,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
