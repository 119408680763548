import React, {
    useMemo,
} from 'react'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    getUrlsFromTableConfig,
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import useRouteStateFilter from 'app/hooks/useRouteStateFilter'

import useTabsByPermission from 'app/hooks/useTabsByPermission'
import getConfig from './LaneGroups.config'
import request from './LaneGroup.requests'
import LaneGroupOverview from './LaneGroupOverview'
import LaneFees from './LaneFees'
import Lanes from './Lanes'
import LaneGroupChangeHistory from './LaneGroupChangeHistory'
import usePricingRoutes from '../hooks/usePricingRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const LaneGroups = () => {
    const {
        LaneGroups: LaneGroupsRoutes,
    } = usePricingRoutes()
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: LaneGroupsRoutes.Overview.route,
                Component: LaneGroupOverview,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes('overview')
                },
            },
            permission: true,
        },
        {
            tab: {
                label: 'Lanes',
                url: LaneGroupsRoutes.Lanes.route,
                Component: Lanes,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes('lanes')
                },
            },
            permission: true,
        },
        {
            tab: {
                label: 'Lane Fees',
                url: LaneGroupsRoutes.LaneFees.route,
                Component: LaneFees,
                activeComparer: (pathname) => {
                    return pathname.split('/').includes('lane-fees')
                },
            },
            permission: true,
        },
        {
            tab: {
                label: 'Change History',
                url: LaneGroupsRoutes.ChangeHistory.route,
                Component: LaneGroupChangeHistory,
            },
            permission: true,
        },
    ])
    const filter = useRouteStateFilter()

    const tableConfig = useMemo(() => {
        return getConfig()
    }, [])

    return (
        <SkyNetDomain
            sidebarTabs={tabs}
            name={tableConfig.name}
            defaultTab={LaneGroupsRoutes.Overview.route}
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                moduleName={request.domainName}
                filter={filter}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default LaneGroups
