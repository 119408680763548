import React, {
    useState, useRef,
} from 'react'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    useSharedContext,
} from 'app/shared-components/SharedContext'
import useHasUnreadAlerts from './hooks/useHasUnreadAlerts'
import NotificationsList from './NotificationsList'

const AlertNotifications = () => {
    const height: number = useSharedContext() || 0
    const alertsReadPermission = useHasPermission(['alerts_read'])
    const bellRef = useRef<HTMLDivElement>(null)
    const [
        anchorEl,
        setAnchorEl,
    ] = useState<HTMLDivElement | null>(null)

    const onClick = (
        event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => {
        setAnchorEl(event.currentTarget)
    }

    const onClose = () => {
        setAnchorEl(null)
    }
    const {
        data: {
            hasUnreadAlerts,
        } = {},
        invalidate,
    } = useHasUnreadAlerts({
        enabled: alertsReadPermission,
    })

    if (!alertsReadPermission) {
        return null
    }

    const iconPath = `/assets/images/icons/notification_bell${hasUnreadAlerts ? '_unread' : ''}.svg`

    return (
        <>
            <div
                data-testid="IconContainer"
                ref={bellRef}
                role="button"
                onClick={onClick}
                onKeyDown={onClick}
                tabIndex={0}
            >
                <img
                    data-testid="Icon"
                    src={iconPath}
                    alt="alert"
                />
            </div>
            <NotificationsList
                open={Boolean(anchorEl)}
                onClose={onClose}
                anchorEl={anchorEl}
                anchorPosition={{
                    top: height ?? 0,
                    left: bellRef?.current?.getBoundingClientRect()?.right ?? 0,
                }}
                hasUnreadAlertsInvalidate={invalidate}
            />
        </>
    )
}

export default AlertNotifications
