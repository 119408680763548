import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        tabsWrapper: {
            top: '1px',
            left: 0,
            width: '100%',
            display: 'flex',
            overflow: 'hidden',
            position: 'absolute',
            overflowX: 'auto',
            justifyContent: 'space-between',
        },
        tabs: {
            display: 'flex',
            alignContent: 'center',
        },
        tabContentWrapper: {
            height: `calc(100% - ${Number(theme.shape.tabHeight) + 2}px)`,
            top: Number(theme.shape.tabHeight) - 1,
            left: 0,
            width: '100%',
            position: 'absolute',
            display: 'flex',
            flexGrow: 5,
            backgroundColor: theme.palette.secondary[50],
            borderWidth: 1,
            borderStyle: 'solid',
            borderLeftWidth: 0,
            borderColor: theme.palette.secondary[300],
            '@media print': {
                borderWidth: 0,
                backgroundColor: theme.palette.common.white,
            },
        },
        tabContent: {
            height: `calc(100% - ${Number(theme.shape.tabHeight) + 2}px)`,
            width: `calc(100% - ${theme.spacing(3)} - ${theme.spacing(1.5)})`,
            padding: theme.spacing(1.5),
            paddingLeft: theme.spacing(3),
            overflow: 'auto',
        },
    }
})

export default useStyles
