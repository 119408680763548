import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import Overview from './Overview'
import LaneImplementation from './LaneImplementation'
import Notifications from './Notifications'
import tableConfig from './LaneOpsInformationDto.config'
import useOrderManagementRoutes from '../hooks/useOrderManagementRoutes'

const DOMAIN_NAME = 'LaneOpsInformationDto'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const LanesTable = () => {
    const {
        Lanes: LanesRoutes,
    } = useOrderManagementRoutes()
    const tabs: SidebarTab[] = [
        {
            url: LanesRoutes.Overview.route,
            label: 'Overview',
            Component: Overview,
        },
        {
            url: LanesRoutes.LaneImplementation.route,
            label: 'Lane Implementation',
            Component: LaneImplementation,
        },
        {
            url: LanesRoutes.Notifications.route,
            label: 'Notifications',
            Component: Notifications,
        },
    ]

    return (
        <SkyNetDomain
            name={DOMAIN_NAME}
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                customUrl="lane/filter-request/ops-overview"
                showSearch
            />
        </SkyNetDomain>
    )
}

export default LanesTable
