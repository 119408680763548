import PropTypes from 'prop-types'

const CLEAR_FILTERS = 'clearFilters'
const CLEAR_SORTING = 'clearSorting'
const PRINT_QUOTES = 'printQuotes'
const SEARCH = 'search'
const SHOW_ARCHIVED = 'showArchived'
const BULK_CREATE = 'bulkCreate'
const BULK_EDIT = 'bulkEdit'

export const VISIBLE_COLUMNS = 'visibleColumns'

export const TABLE_CONTROL_TYPES = {
    clearFilters: CLEAR_FILTERS,
    clearSorting: CLEAR_SORTING,
    search: SEARCH,
    showArhived: SHOW_ARCHIVED,
    bulkCreate: BULK_CREATE,
    bulkEdit: BULK_EDIT,
}

export const tableControlsPropType = PropTypes.arrayOf(PropTypes.oneOf([
    CLEAR_SORTING,
    CLEAR_FILTERS,
    PRINT_QUOTES,
    SEARCH,
    SHOW_ARCHIVED,
    BULK_CREATE,
    BULK_EDIT,
]))

export enum TableControlTypes {
    ClearFilters = 'clearFilters',
    ClearSorting = 'clearSorting',
    Search = 'search',
    ShowArhived = 'showArchived',
    BulkCreate = 'bulkCreate',
    BulkEdit = 'bulkEdit',
}
