import React, {
    useMemo,
} from 'react'

import {
    Navigate,
} from 'react-router-dom'
import {
    CommonRoutes, SkyNetRouteConfigObject, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import Countries from './Countries'
import {
    MasterdataDomains,
} from './Masterdata.types'

export default function Masterdata() {
    const routesConfig: SkyNetRouteConfigObject<MasterdataDomains> = useMemo(() => {
        return {
            [MasterdataDomains.COUNTRIES]: <Countries />,
            [CommonRoutes.SLASH]: <Navigate to={`./${MasterdataDomains.COUNTRIES}`} />,
        }
    }, [])

    return useSkyNetRoutes({
        routesConfig,
    })
}
