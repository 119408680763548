import {
    LoggerType,
    LoggerPosition,
} from 'app/Apps/Loggers'
import {
    Location,
} from 'app/shared-components/LocationSelector'
import {
    ContainerStatus,
    DamageStatus,
    DamageType,
    DefaultTemperatureRange,
    TemperatureRangeAll,
    ContainerType as ContainerTypeEnum,
    ContainerCode,
} from 'app/types/enums'

export type ContainerPicture = {
    attachmentUrl: string,
    changedBy: string,
    changedOn: string,
    createdBy: string,
    createdOn: string,
    deleted: boolean,
    fileId: string,
    fileSize: number,
    filename: string,
    id: number,
    mimetype: string
}

export type ContainerType = {
    applicableTempRange: TemperatureRangeAll,
    changedBy: string,
    changedOn: string,
    containerCode: ContainerCode,
    containerGeneration: string,
    containerModel: string,
    containerPicture: ContainerPicture,
    containerPictureId: string | number,
    containerVolume: string,
    createdBy: string,
    createdOn: string,
    defaultLoggerType: string,
    defaultTempRange: DefaultTemperatureRange,
    deleted: boolean,
    extDimHeight: number,
    extDimLength: number,
    extDimWidth: number,
    extStorageRangeEnd: number,
    extStorageRangeStart: number,
    extTempRangeEnd: number,
    extTempRangeStart: number,
    id: number,
    intDimHeight: number,
    intDimLength: number
    intDimWidth: number,
    intTempRangeEnd: number,
    intTempRangeStart: number,
    isInOperationalUse: boolean,
    isPrototype: boolean,
    isQuotable: boolean,
    maxGrossWeight: number,
    tareWeight: number,
    temperatureRange: TemperatureRangeAll,
    toleranceRange: number,
    containerModelInternalDry: ContainerTypeEnum,
}

export type LoggerContainerInfo = {
    exchangeTimestamp: string,
    id: number,
    isCurrentLogger: boolean,
    isDefaultLogger: boolean,
    logger?: {
        loggerNumber: string,
        loggerType: LoggerType,
        loggerTypeDetails: {
            dataTypes: string[],
            loggerType: LoggerType,
        },
    },
    loggerPositionConfiguration: LoggerPosition,
    removedOn: string,
    sensorPositions: string[],
}

export type DeletedLogger = LoggerContainerInfo & {
    deletedBy: string,
    deletedOn: string,
}

export type Container = {
    containerDescription?: string,
    containerStatus: ContainerStatus,
    containerType: {
        containerCode: string,
        containerModel: string,
    },
    defaultAmbientLoggerType?: LoggerType,
    defaultInternalLoggerType?: LoggerType,
    id: number,
    jypId: string,
    lastMeasuredOnUtcAmbient: number,
    lastMeasuredOnUtcInternal: number,
    lastMeasuredTempInternal: number,
    lastMeasuredTempAmbient: number,
    loggerExchangedOnUtcAmbient: number,
    loggerExchangedOnUtcInternal: number,
    location: Location,
    manufacturingTimestamp: string,
    material?: string,
    requiredLoggerExchange: LoggerType[],
    serialNumber: string,
    tempRange: TemperatureRangeAll,
    financingContract: string,
    containerModel: number,
    productType: string,
}

export type CreateContainerForm = {
    containerType: number,
    serialNumberSuffixFrom: number,
    numberOfContainers: number,
    location: number,
    financingContract: string,
    containerModel: number,
}

export type CreateContainerFields = keyof CreateContainerForm

export type Containers = {
    serialNumber: string,
    containerTypeContainerModel: string,
    tempRange: TemperatureRangeAll,
    containerStatusEnriched: ContainerStatus,
    containerStatusEnrichedChangedOnUtc: string,
    currentOrderOrderNumber: string,
    locationLocationName: string,
    defaultInternalLoggerType: LoggerType,
    defaultAmbientLoggerType: LoggerType,
    batteryLevelAmbient: number,
    lastMeasuredTempInternal:number,
    lastMeasuredOnUtcInternal: number,
    loggerExchangedOnUtcInternal: number,
    batteryLevelInternal: number,
    lastMeasuredTempAmbient: number,
    lastMeasuredOnUtcAmbient: number,
    loggerExchangedOnUtcAmbient: number,
    id: number,
}

export type DamageByContainerType = {
    damageNumber: string,
    damageType: DamageType,
    damageStatus: DamageStatus,
    id: number,
    reportedOn: string,
    repairedOn: string,
    repairingServiceCenter: { id: number }
}

export type ContainerGeoLocation = {
    longitude: number,
    latitude: number,
    timestamp: string,
}

export enum ContainersTabs {
    ChangeStatus = 'changestatus',
    Loggers = 'loggers',
    SensorData = 'sensordata',
    Damages = 'damages',
    GeoLocation = 'geolocation',
}
