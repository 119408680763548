import React, {
    useMemo,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab, getUrlsFromTableConfig,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'

import LoggerOverview from './LoggerOverview'
import LoggerCreate from './LoggerCreate'
import LoggerUpdate from './LoggerUpdate'
import Documents from './Documents'
import LoggerChangeHistory from './LoggerChangeHistory'
import getConfig from './Logger.config'
import useLoggerRoutes from './hooks/useLoggerRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Loggers = () => {
    const {
        Loggers: LoggerRoutes,
    } = useLoggerRoutes()
    const loggerCreate = useHasPermission(['logger_create'])
    const loggerUpdate = useHasPermission(['logger_update'])
    const canLoggerRead = useHasPermission(['logger_read'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: LoggerRoutes.Overview.route,
                params: LoggerRoutes.Overview.stringParams,
                label: 'Overview',
                Component: LoggerOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: LoggerRoutes.Update.route,
                label: 'Data Update',
                Component: LoggerUpdate,
            },
            permission: loggerUpdate,
        },
        {
            tab: {
                url: LoggerRoutes.Documents.route,
                label: 'Documents',
                Component: Documents,
            },
            permission: canLoggerRead,
        },
        {
            tab: {
                label: 'Change History',
                url: LoggerRoutes.ChangeHistory.route,
                Component: LoggerChangeHistory,
            },
            permission: true,
        },
    ])

    const tableConfig = useMemo(() => {
        return getConfig()
    }, [])

    return (
        <SkyNetDomain
            name="LoggerView"
            actionComponents={{
                Create: loggerCreate ? LoggerCreate : undefined,
            }}
            sidebarTabs={tabs}
            defaultTab="overview"
            uniqField="loggerNumber"
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                moduleName="Logger"
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Loggers
