import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import AlLogisticPricingCreate from './AlLogisticPricingCreate'
import AlLogisticPricingOverview from './AlLogisticPricingOverview'

import tableConfig from './AlLogisticPricing.config'
import request from './AlLogisticPricing.requests'
import usePricingRoutes from '../hooks/usePricingRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const AlLogisticPricing = () => {
    const {
        AlLogisticPricing: AlLogisticPricingRoutes,
    } = usePricingRoutes()

    const tabs: SidebarTab[] = [{
        url: AlLogisticPricingRoutes.Update.route,
        label: 'Al Logistic Pricing',
        Component: AlLogisticPricingOverview,
    }]

    return (
        <SkyNetDomain
            sidebarTabs={tabs}
            actionComponents={{
                Create: AlLogisticPricingCreate,
            }}
            name={tableConfig.name}
            defaultTab={AlLogisticPricingRoutes.Update.route}
        >
            <SkyNetTable
                moduleName={request.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>

    )
}

export default AlLogisticPricing
