import React, {
    useCallback,
} from 'react'
import AddIcon from '@mui/icons-material/Add'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import Button from 'app/shared-components/Button'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    RoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import {
    Order, OrderTypes,
} from '../Orders/orders.types'
import useOrderManagementRoutes from '../hooks/useOrderManagementRoutes'
import useStyles from './DailyOps.style'
import toBeConfirmedConfig from './configs/toBeConfirmed.config'
import deliveryViaRoadPickUpConfig from './configs/deliveryViaRoadPickUp.config'
import deliveryViaRoadInfoConfig from './configs/deliveryViaRoadInfo.config'
import deliveryViaRoadConfirmConfig from './configs/deliveryViaRoadConfirm.config'
import pickupViaRoadConfig from './configs/pickupViaRoad.config'
import airTransportConfig from './configs/airTransport.config'
import confirmationDropOffConfig from './configs/confirmation.config'
import missingInformationConfig from './configs/missingInformation.config'
import containerSelectionConfig from './configs/containerSelection.config'
import pqOrdersConfig from './configs/pqOrdersConfig'
import {
    getDailyOpsNavigate,
} from './DailyOps.utils'

const DOMAIN_NAME = orderManagementRequest.DailyOps.name
const UNIQ_FIELD = orderManagementRequest.DailyOps.uniqField

const confirmationUnloadingConfig = {
    ...confirmationDropOffConfig,
    tabs: [{
        name: 'Confirmation Of Unloading: Info',
        url: 'all',
    }],
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const DailyOps = () => {
    const {
        classes,
    } = useStyles()
    const Routes = useOrderManagementRoutes()

    const hasAutomaticQuotePermission: boolean = useHasPermission(['contract_basis_automatic_quote'])

    const openCustomerCreateForm = useCallback((): void => {
        Routes.CustomerOrders.CreateDirectLease.navigate()
    }, [Routes])

    const openCustomerCreateFormNew = useCallback((): void => {
        Routes.CustomerOrders.CreateAirlineLease.navigate()
    }, [Routes])

    const openInternalCreateForm = useCallback((): void => {
        Routes.InternalOrders.Create.navigate()
    }, [Routes])

    const onRowClick = useCallback((name: string, {
        orderNumber,
        orderType,
    }: Order): void => {
        return getDailyOpsNavigate({
            name,
            Route: (orderType === OrderTypes.CUSTOMER_TRANSPORT
                ? Routes.CustomerOrders
                : Routes.InternalOrders) as RoutesConfig,
        })({
            params: {
                key: orderNumber,
            },
        })
    }, [Routes])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Customer Transports
                <div className={classes.actionWrapper}>
                    <Button
                        label="Create Direct Lease"
                        onClick={openCustomerCreateForm}
                        data-testid="openCustomerCreateFormBtn"
                        icon={<AddIcon />}
                        className={classes.button}
                    />
                    {
                        hasAutomaticQuotePermission && (
                            <Button
                                label="Create AirLine Lease"
                                onClick={openCustomerCreateFormNew}
                                data-testid="openCustomerCreateFormBtnNew"
                                icon={<AddIcon />}
                                className={classes.button}
                            />
                        )
                    }
                </div>
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('pqOrders', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.pqOrders}
                    tableConfig={pqOrdersConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    showSearch
                    data-testid="pqOrders"
                    paginationModeMatches
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('toBeConfirmed', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.toBeConfirmed}
                    tableConfig={toBeConfirmedConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="toBeConfirmed"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('deliveryViaRoadInfo', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.deliveryViaRoadInfo}
                    tableConfig={deliveryViaRoadInfoConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="deliveryViaRoadInfo"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('deliveryViaRoadAddPickup', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.deliveryViaRoadAddPickup}
                    tableConfig={deliveryViaRoadPickUpConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="deliveryViaRoadAddPickup"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('deliveryViaRoadConfirmation', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.deliveryViaRoadConfirmation}
                    tableConfig={deliveryViaRoadConfirmConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="deliveryViaRoadConfirmation"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('pickupViaRoadConfirmation', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.pickupViaRoadConfirmation}
                    tableConfig={pickupViaRoadConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="pickupViaRoadConfirmation"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('airTransportByCustomerInfo', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.airTransportByCustomerInfo}
                    tableConfig={airTransportConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="airTransportByCustomerInfo"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('confirmationOfDropOffInfo', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.confirmationOfDropOffInfo}
                    tableConfig={confirmationDropOffConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="confirmationOfDropOffInfo"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('confirmationOfUnloadingInfo', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.confirmationOfUnloadingInfo}
                    tableConfig={confirmationUnloadingConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="confirmationOfUnloadingInfo"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.headerTitle}>
                Internal Transports
                <div className={classes.actionWrapper}>
                    <Button
                        label="Create Internal Order"
                        onClick={openInternalCreateForm}
                        data-testid="openInternalCreateFormBtn"
                        icon={<AddIcon />}
                        className={classes.button}
                    />
                </div>
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('missingInformation', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.missingInformation}
                    tableConfig={missingInformationConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="missingInformation"
                    tableControls={tableControls}
                />
            </div>
            <div className={classes.headerTitle}>Container Selection</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    onRowClick={(args) => { onRowClick('containerSelection', args) }}
                    customUrl={orderManagementRequest.DailyOps.url.containerSelection}
                    tableConfig={containerSelectionConfig}
                    uniqField={UNIQ_FIELD}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                    data-testid="containerSelection"
                    tableControls={tableControls}
                />
            </div>
        </div>
    )
}

export default DailyOps
