/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import PrintLabel from './PrintLabel'

const PrintLabelContainer = (props: Readonly<{ to: string }>) => {
    const navigate = useNavigate()
    const {
        to,
    } = props

    const openPrintDialog = useCallback(() => {
        navigate(to)
    }, [
        navigate,
        to,
    ])

    return (
        <PrintLabel
            {...props}
            onClick={openPrintDialog}
        />
    )
}

export default PrintLabelContainer
