import React, {
    useCallback,
    useMemo,
} from 'react'
import omit from 'lodash/omit'

import StatusHandler from 'app/shared-components/StatusHandler'
import HeaderWarning from 'app/shared-components/HeaderWarning'
import {
    REDIRECT_PARAM,
} from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'
import {
    PricingStatus,
} from 'app/types/enums'
import idRemover from 'app/utils/idRemover'

import AlLeasePricingForm from '../AlLeasePricingForm'
import useGetAlLeasePricing from '../hooks/useGetAlLeasePricing'
import AlLeasePricingRequests from '../AlLeasePricing.requests'
import usePricingRoutes from '../../hooks/usePricingRoutes'

type Props = {
    id: number
}

const AlLeasePricingCopy = ({
    id,
}: Props) => {
    const {
        AlLeasePricing: AlLeasePricingRoutes,
    } = usePricingRoutes()

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAlLeasePricing(id)

    const onSuccess = useCallback(({
        id: copyId,
    }) => {
        invalidate()
        AlLeasePricingRoutes.Update.navigate({
            params: {
                key: copyId,
            },
            options: {
                state: REDIRECT_PARAM,
            },
        })
    }, [
        AlLeasePricingRoutes,
        invalidate,
    ])

    const modifyDataBeforeSend = useCallback((newData) => {
        return {
            ...omit(newData, [
                'id',
                'status',
            ]),
            leaseFees: newData.leaseFees && idRemover(newData.leaseFees),
        }
    }, [])
    const value = useMemo(() => {
        return {
            ...data,
            status: PricingStatus.IN_PROGRESS,
        }
    }, [data])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <HeaderWarning>
                    You are deep copying a Al Lease Pricing.
                </HeaderWarning>
                <AlLeasePricingForm
                    data={value}
                    modifyDataBeforeSend={modifyDataBeforeSend}
                    onSuccess={onSuccess}
                    requestParam={AlLeasePricingRequests.create()}
                />
            </>

        </StatusHandler>
    )
}

export default AlLeasePricingCopy
