import AllCellComponentsSpecialProps from 'app/Apps/DomainObject/ComponentMapper'
import {
    FilteredBaseColumn,
} from 'app/types/richtable.types'

export type SkyNetTableTab = {
    name: string,
    url: string,
    namedQuery?: string,
    isNamedQueryByUrl?: boolean,
    requestUrl?:string,
    includeFilters?: Record<string, any>, // to pass filters as includeFilters: {} to request body
    filters?: Record<string, any>, // to pass filters as filters: {} to request body
}

export type SkyNetTableTabWithLabel = SkyNetTableTab & {label: string}

export type SkyNetTableConfig<T = {}> = {
    labelEntityName?: string,
    config?: () => any,
    tabs?: SkyNetTableTab[],
    fields?: SkyNetTableField<T>[],
    name?: string,
}

export type SidebarTab = {
    url: string,
    label: string,
    params?: string,
    Component?: (props: any) => JSX.Element,
    condition?: (data: any)=> boolean,
    activeComparer?: (pathname: string, tabUrl: string)=> boolean,
}

type SkyNetTableFieldPropsMapper = {
    lowerTempBoundary?: string,
    higherTempBoundary?: string,
    link?: string,
}

export enum RangeType {
    TemperatureRange = 'temperatureRange',
    DayRange = 'dayRange'
}

type SkyNetTableFieldBase<T = {}> = {
    id?: string,
    name?: T,
    reduced?: boolean,
    width?: string,
    minWidth?: string,
    propsMapper?: SkyNetTableFieldPropsMapper,
    sorting?: boolean,
    mapCellProps?: string | ((props: Record<string, any>) => Record<string, any>),
    Cell?: (props: Record<string, any>)=> JSX.Element,
    labelKey?: string,
    fxRateType?: string,
    isHidden?: boolean,
} & FilteredBaseColumn

export type SkyNetTableField<T = {}> = SkyNetTableFieldBase<T> & AllCellComponentsSpecialProps
