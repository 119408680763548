import React, {
    useState, useMemo, useCallback,
} from 'react'
import {
    useLocation, useNavigate,
} from 'react-router-dom'

import SkyNetStepper from 'app/shared-components/SkyNetStepper'
import HistoryBlockPrompt from 'app/shared-components/HistoryBlockPrompt'
import useBeforeUnloadDialog from 'app/hooks/useBeforeUnloadDialog'

import {
    PreBookingEntity,
    PreBookingStatus,
    PreBookingContainerStatus,
} from '../PreBooking.types'
import PreBookingAvailableLanes from '../components/PreBookingAvailableLanes'
import PreBookingLaneDetails from '../components/PreBookingLaneDetails'
import {
    PreBookingInfoContext,
} from '../components/PreBookingInfoContext/PreBookingInfoContext'
import usePreBookingCreate from './hooks/usePreBookingCreate'

const defaultState = {}
const PreBookingCreate = () => {
    const [
        value,
        setValue,
    ] = useState<PreBookingEntity>(defaultState as PreBookingEntity)
    const {
        pathname,
    } = useLocation()
    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>()
    const navigate = useNavigate()

    const [
        isLoading,
        setIsLoading,
    ] = useState<boolean>(false)

    useBeforeUnloadDialog(isEdited)

    const [
        activeStep,
        setActiveStep,
    ] = useState<number>(0)

    const onSuccess = useCallback(({
        id,
    }) => {
        navigate(pathname.replace(/\/create$/, `/edit/${id}/overview`))
    }, [
        navigate,
        pathname,
    ])

    const createPreBooking = usePreBookingCreate({
        onSuccess,
        setIsEdited,
        setIsLoading,
    })

    const stepperSteps = useMemo(() => {
        return [
            {
                key: '1',
                label: 'Available Lanes',
                expanded: true,
                content: <PreBookingAvailableLanes
                    disabled={activeStep !== 0}
                />,
            },
            {
                key: '2',
                label: 'Lane Details',
                expanded: (step) => {
                    return step > 0
                },
                content: <PreBookingLaneDetails
                    disabled={activeStep !== 1}
                    nextAction={createPreBooking}
                    defaultValues={{
                        preBookingStatus: PreBookingStatus.PENDING,
                        containersStatus: PreBookingContainerStatus.PENDING,
                    }}
                    isLoading={isLoading}
                />,
            },
        ]
    }, [
        activeStep,
        createPreBooking,
        isLoading,
    ])

    const preBookingInformationContext = useMemo(() => {
        return {
            activeStep,
            setActiveStep,
            value,
            setValue,
            setIsEdited,
        }
    }, [
        activeStep,
        setActiveStep,
        value,
        setValue,
    ])

    return (
        <PreBookingInfoContext.Provider value={preBookingInformationContext}>
            <SkyNetStepper
                activeStep={activeStep}
                steps={stepperSteps}
            />
            <HistoryBlockPrompt
                when={isEdited}
                positiveLabel="Discard"
                isPositiveAlert
                promptText="You are about to leave this page, your progress will be lost. Would you like to discard your changes anyway?"
            />
        </PreBookingInfoContext.Provider>
    )
}

export default PreBookingCreate
