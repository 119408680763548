import {
    useContext,
} from 'react'

import SkyNetDomainContext from './SkyNetDomainContext'

const useSkyNetDomainContext = () => {
    return useContext(SkyNetDomainContext)
}

export default useSkyNetDomainContext
