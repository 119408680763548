import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'

import {
    AddressCategory,
    LocationType,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'

export const getTabUrl = (name: LocationType) => {
    const tabUrls = {
        [LocationType.ADDRESS]: 'address',
        [LocationType.AIRPORT]: 'airport',
        [LocationType.SEAPORT]: 'seaport',
    }

    return tabUrls[name] || CommonRoutes.ALL
}

const config = ():SkyNetTableConfig => {
    return {
        name: 'LocationTable',
        tabs: [
            {
                name: 'All Locations',
                url: CommonRoutes.ALL,
            },
            {
                name: 'Addresses',
                url: getTabUrl(LocationType.ADDRESS),
                includeFilters: {
                    locationType: [LocationType.ADDRESS],
                },
            },
            {
                name: 'Airports',
                url: getTabUrl(LocationType.AIRPORT),
                includeFilters: {
                    locationType: [LocationType.AIRPORT],
                },
            },
            {
                name: 'Seaports',
                url: getTabUrl(LocationType.SEAPORT),
                includeFilters: {
                    locationType: [LocationType.SEAPORT],
                },
            },
        ],
        fields: [
            {
                name: 'locationName',
                reduced: true,
            },
            {
                name: 'addressLine1',
                filterType: FilterType.Array,
            },
            {
                name: 'zip',
                filterType: FilterType.Array,
            },
            {
                name: 'city',
                reduced: true,
            },
            {
                name: 'countryName',
                filterField: 'countryName',
                filterType: FilterType.Array,
                reduced: true,
                labelKey: 'country',
            },
            {
                name: 'isMainAddress',
                componentName: 'ChipCheckMark',
            },
            {
                name: 'companyName',
                filterField: 'companyName',
                filterType: FilterType.Array,
                labelKey: 'company',
            },
            {
                name: 'addressCategory',
                componentName: 'EnumList',
                allowedValuesEnum: AddressCategory,
            },
        ],
    }
}

export default config
