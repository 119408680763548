import React from 'react'
import {
    CommonRoutes,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import AccountLaneUpdate from '../AccountLaneUpdate'
import AccountLaneCopy from '../AccountLaneCopy'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

export default ({
    id,
}: {id: number}) => {
    const {
        Lanes: LanesRoutes,
    } = useAccountManagementRoutes()

    return useSkyNetRoutes({
        routes: [
            {
                path: CommonRoutes.SLASH,
                element: <AccountLaneUpdate
                    id={id}
                    disabled
                />,
            },
            {
                path: LanesRoutes.Copy.stringParams,
                element: <AccountLaneCopy id={id} />,
            },
        ],
    })
}
