import React, {
    useCallback,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'

import StatusHandler from 'app/shared-components/StatusHandler'
import useGetAccountQuote from '../hooks/useGetAccountQuote'
import AccountQuoteUpdate from './AccountQuoteUpdate'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

const AccountQuoteUpdateContainer = ({
    id,
}: {id: number}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetAccountQuote(id)
    const {
        Quotes: QuotesRoutes,
    } = useAccountManagementRoutes()

    const {
        state,
    } = useLocation()

    const onSuccess = useCallback(() => {
        if (state?.path) {
            QuotesRoutes.Overview.navigate({
                params: {
                    key: data?.id,
                },
            })
        }

        invalidate()
    }, [
        state?.path,
        invalidate,
        QuotesRoutes.Overview,
        data?.id,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <AccountQuoteUpdate
                data={data}
                onSuccess={onSuccess}
            />
        </StatusHandler>
    )
}

export default AccountQuoteUpdateContainer
