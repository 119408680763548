import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    AddressCategory,
} from 'app/types/enums'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

const config: SkyNetTableConfig<keyof Location> = {
    name: 'Addresses',
    labelEntityName: 'Location',
    fields: [
        {
            name: 'locationName',
            labelKey: 'Location.locationName',
        },
        {
            name: 'addressLine1',
            labelKey: 'Location.addressLine1',
        },
        {
            name: 'city',
            labelKey: 'Location.city',
        },
        {
            name: 'addressCategory',
            componentName: 'EnumList',
            allowedValuesEnum: AddressCategory,
            labelKey: 'Location.addressCategory',
        },
    ],
}

export default config
