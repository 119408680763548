import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

type BillingAddresses = {
    billingLocationName: string,
    companyCompanyName: string,
    street: string
}

const config: SkyNetTableConfig<keyof BillingAddresses> = {
    labelEntityName: 'BillingCompanyDto',
    fields: [
        {
            name: 'billingLocationName',
            labelKey: 'BillingCompanyDto.billingLocationName',
        },
        {
            name: 'companyCompanyName',
            labelKey: 'BillingCompanyDto.company',
        },
        {
            name: 'street',
            labelKey: 'BillingCompanyDto.street',
        },
    ],
}

export default config
