import {
    useMemo,
} from 'react'
import {
    App, CommonRoutes, useSkyNetNavigate,
} from 'app/SkyNetRoutes'
import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import useSkyNetDomainRoutes, {
    SkyNetDomainSubRoutes,
} from 'app/shared-components/SkyNetDomain/useSkyNetDomainRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    OrderManagementDomains,
} from '../OrderManagement.types'
import {
    LanesTabs,
} from '../Lanes/lanes.types'
import {
    CustomerTransportTabs,
} from '../Orders/CustomerTransport/CustomerTransport.types'
import {
    InternalTransportTabs,
} from '../Orders/InternalTransport/InternalTransport.types'

export default function useOrderManagementRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useSkyNetDomainRoutes()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.ORDER_MANAGEMENT],
            DailyOps: {
                route: OrderManagementDomains.DailyOps,
            },
            Pooling: {
                route: OrderManagementDomains.Pooling,
            },
            OpenBookings: {
                route: OrderManagementDomains.OpenBookings,
            },
            Lanes: {
                route: OrderManagementDomains.Lanes,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                LaneImplementation: {
                    route: LanesTabs.LaneImplementation,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Notifications: {
                    route: LanesTabs.Notifications,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            CustomerOrders: {
                route: OrderManagementDomains.CustomerOrders,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Notifications: {
                    route: CustomerTransportTabs.Notifications,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                OrderChanges: {
                    route: CustomerTransportTabs.OrderChanges,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ContainerSelection: {
                    route: CustomerTransportTabs.ContainerSelection,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                OrderSteps: {
                    route: CustomerTransportTabs.OrderSteps,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                BillingInformation: {
                    route: CustomerTransportTabs.BillingInformation,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Invoices: {
                    route: CustomerTransportTabs.Invoices,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                SharedPositioning: {
                    route: CustomerTransportTabs.SharedPositioning,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                PositioningSetup: {
                    route: CustomerTransportTabs.PositioningSetup,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                CreateAirlineLease: {
                    route: OrderManagementDomains.CreateAirlineLease,
                    via: SkyNetDomainRoutes.All,
                },
                CreateDirectLease: {
                    route: OrderManagementDomains.CreateDirectLease,
                    via: SkyNetDomainRoutes.All,
                },
            },
            InternalOrders: {
                route: OrderManagementDomains.InternalOrders,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Notifications: {
                    route: InternalTransportTabs.Notifications,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ContainerSelection: {
                    route: InternalTransportTabs.ContainerSelection,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                OrderSteps: {
                    route: InternalTransportTabs.OrderSteps,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Create: {
                    route: SkyNetDomainSubRoutes.CREATE,
                    via: SkyNetDomainRoutes.All,
                },
            },
            PreBookings: {
                route: OrderManagementDomains.PreBookings,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
