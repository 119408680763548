import {
    tss,
} from 'app/tss'

export default tss.withNestedSelectors().create(({
    theme,
}) => {
    return {
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
        },
        header: {
            height: '50px',
            minHeight: '50px',
            paddingBottom: 2,
            '@media print': {
                display: 'none',
            },
        },
        main: {
            display: 'flex',
            height: 'calc(100% - 50px)',
            width: '100%',
        },
        body: {
            height: `calc(100% - ${theme.spacing(4)})`,
            width: `calc(100% - ${theme.spacing(4)})`,
            position: 'relative',
            overflow: 'auto',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.secondary[100],
            display: 'flex',
            '@media print': {
                padding: 0,
            },
        },
        sidebar: {
            width: 0,
            position: 'relative',
            '@media print': {
                display: 'block',
                position: 'fixed',
                height: '100%',
                left: 0,
                top: -9,
            },
        },
        menu: {
            paddingTop: theme.spacing(2),
            zIndex: 3,
            marginTop: -2,
            width: '65px',
            background: theme.palette.primary[400],
            '@media print': {
                display: 'none',
            },
        },
    }
})
