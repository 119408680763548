import React, {
    useCallback,
    useMemo,
} from 'react'
import {
    Navigate, RouteObject,
    useNavigate,
} from 'react-router-dom'
import useSkyNetRoutes from 'app/SkyNetRoutes/hooks/useSkyNetRoutes'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    SidebarTab,
    ActionComponentsType,
} from './SkyNetDomain.types'
import useSkyNetDomainRoutes from './useSkyNetDomainRoutes'
import useCreateSkyNetDomainContext from './useCreateSkyNetDomainContext'
import SkyNetDomainContext from './SkyNetDomainContext'
import SkyNetDomainForm from './SkyNetDomainForm'
import SkyNetDomainTabs from './SkyNetDomainTabs'

const SkyNetDomain = ({
    children,
    actionComponents: ActionComponents,
    defaultTab,
    name,
    domainRequestKey,
    sidebarTabs,
    getDomainObject,
    editable = true,
    uniqField,
    subdomainUrls = [CommonRoutes.ALL],
}: Readonly<{
    children: JSX.Element,
    actionComponents?: ActionComponentsType,
    defaultTab?: string,
    name?: string,
    domainRequestKey?: RequestKeys,
    uniqField?: string,
    sidebarTabs?: SidebarTab[],
    getDomainObject?: (params: Record<string, any>) => RequestConfig,
    editable?: boolean,
    subdomainUrls?: string[]
}>) => {
    const Routes = useSkyNetDomainRoutes()
    const navigate = useNavigate()
    const goBack = useCallback(() => {
        navigate('..')
    }, [navigate])

    const creatable = Boolean(ActionComponents?.Create)
    const printable = Boolean(ActionComponents?.Print)
    const bulkCreatable = Boolean(ActionComponents?.BulkCreate)
    const bulkEditable = Boolean(ActionComponents?.BulkEdit)

    const childForms: RouteObject[] = useMemo(() => {
        const forms = []

        if (creatable) {
            forms.push({
                path: Routes.All.Create.route,
                element: (
                    <SkyNetDomainForm onClose={goBack}>
                        <ActionComponents.Create />
                    </SkyNetDomainForm>
                ),
            })
        }

        if (printable) {
            forms.push({
                path: Routes.All.Print.route,
                element: (
                    <SkyNetDomainForm onClose={goBack}>
                        <ActionComponents.Print />
                    </SkyNetDomainForm>
                ),
            })
        }

        if (editable) {
            forms.push({
                path: `${Routes.All.Edit.route}/${Routes.All.Edit.stringParams}/${CommonRoutes.ASTERISK}`,
                element: (
                    <SkyNetDomainTabs
                        goBack={goBack}
                        tabs={sidebarTabs}
                        getDomainObject={getDomainObject}
                    />
                ),
            })
        }

        return forms
    }, [
        ActionComponents,
        creatable,
        editable,
        getDomainObject,
        goBack,
        printable,
        sidebarTabs,
        Routes,
    ])

    const bulkRoutes: RouteObject[] = useMemo(() => {
        const forms = []
        const backToTable = () => {
            navigate('.')
        }

        if (bulkCreatable) {
            forms.push({
                path: Routes.BulkCreate.route,
                element: <ActionComponents.BulkCreate onClose={backToTable} />,
            })
        }

        if (bulkEditable) {
            forms.push({
                path: Routes.BulkEdit.route,
                element: <ActionComponents.BulkEdit onClose={backToTable} />,
            })
        }

        return forms
    }, [
        ActionComponents,
        bulkCreatable,
        bulkEditable,
        navigate,
        Routes,
    ])

    const skyNetDomainContext = useCreateSkyNetDomainContext({
        defaultTab,
        name,
        domainRequestKey,
        creatable,
        printable,
        editable,
        uniqField,
    })

    const DomainCoreElement = useMemo(() => {
        return (
            <SkyNetDomainContext.Provider value={skyNetDomainContext}>
                {children}
            </SkyNetDomainContext.Provider>
        )
    }, [
        children,
        skyNetDomainContext,
    ])

    return useSkyNetRoutes({
        routes: [
            ...subdomainUrls.map((url) => {
                return {
                    path: url,
                    element: DomainCoreElement,
                    children: childForms,
                }
            }),
            ...bulkRoutes,
            {
                path: CommonRoutes.SLASH,
                element: <Navigate to={Routes.All.route} />,
            },
        ],
    })
}

export default SkyNetDomain
