import React, {
    useCallback,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import internalTransportsConfig from './internalTransports.config'
import customerTransportsConfig from './customerTransports.config'
import preConditioningFeasibilityConfig from './preConditioningFeasibility.config'
import requests from './ContainerSelection.request'

import useStyles from './ContainerSelection.style'
import useOrderManagementRoutes from '../../OrderManagement/hooks/useOrderManagementRoutes'

enum ContainerSelectionTables {
    PreconditioningFeasibility = 'preconditioningFeasibility',
    CustomerTransports = 'customerTransports',
    InternalTransports = 'internalTransports'
}

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const DOMAIN_NAME = requests.name

const ContainerSelection = () => {
    const {
        classes,
    } = useStyles()
    const OrderManagementRoutes = useOrderManagementRoutes()

    const onRowClick = useCallback((name, {
        orderNumber,
    }) => {
        switch (name) {
        case ContainerSelectionTables.PreconditioningFeasibility:
        case ContainerSelectionTables.CustomerTransports:
        default:
            return OrderManagementRoutes.CustomerOrders.ContainerSelection.navigate({
                params: {
                    key: orderNumber,
                },
            })
        case ContainerSelectionTables.InternalTransports:
            return OrderManagementRoutes.InternalOrders.ContainerSelection.navigate({
                params: {
                    key: orderNumber,
                },
            })
        }
    }, [OrderManagementRoutes])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>Pre-Conditioning Feasibility</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(ContainerSelectionTables.PreconditioningFeasibility, args)
                    }}
                    customUrl={requests.preconditioningFeasibility}
                    tableConfig={preConditioningFeasibilityConfig}
                    uniqField="orderNumber"
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>Customer Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(ContainerSelectionTables.CustomerTransports, args)
                    }}
                    customUrl={requests.customerTransports}
                    tableConfig={customerTransportsConfig}
                    uniqField="orderNumber"
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>
                Internal Transports
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={DOMAIN_NAME}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick(ContainerSelectionTables.InternalTransports, args)
                    }}
                    customUrl={requests.internalTransports}
                    tableConfig={internalTransportsConfig}
                    uniqField="orderNumber"
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
        </div>
    )
}

export default ContainerSelection
