import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import {
    Account,
} from 'app/Apps/AccountManagement/Accounts/Account.types'
import config from '../AccountManagement.request'

export default (ids: number[]) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Account[]>({
        key: RequestKeys.getAccounts,
        params: {
            includeFilters: {
                id: ids,
            },
        },
        requestFunc: RequestFn.getEntity(config.getAccounts, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
        keepPreviousData: true,
        enabled: Boolean(ids.length),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}
