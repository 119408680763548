import React, {
    useCallback,
} from 'react'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import Typography from '@mui/material/Typography'

import Card from 'app/shared-components/Card'

import useStyles from '../Locations/LocationOverview/LocationOverview.styles'
import contactColumns from './ContactsColumns.columns'
import {
    Contact,
} from '../Contacts/Contacts.types'
import useContactManagementRoutes from '../hooks/useContactManagementRoutes'

type Props = {
    value?: Contact[],
    isWrapped: boolean,
    onParamsChange?: (params: any) => void,
}

const defaultProps: Partial<Props> = {
    value: [],
    onParamsChange: undefined,
}

const Contacts = ({
    value,
    isWrapped,
    onParamsChange,
}: Props) => {
    const {
        Contacts: ContactsRoutes,
    } = useContactManagementRoutes()
    const {
        classes,
    } = useStyles()

    const loadData = useCallback(() => {
        return Promise.resolve({
            data: value,
        })
    }, [value])

    const onRowClick = useCallback(({
        id: contactId,
    }) => {
        ContactsRoutes.Update.navigate({
            params: {
                key: contactId,
            },
        })
    }, [ContactsRoutes])

    return (
        <div
            className={!isWrapped ? '' : classes.contactsContainer}
        >
            <Card
                fullHeight
                className={classes.cardRoot}
                contentClass={classes.cardContent}
            >
                <div className={classes.header}>
                    <Typography
                        variant="h3"
                    >
                        Contacts Linked to Location
                    </Typography>
                </div>
                <RichTable
                    configName="ContactsLinkedTable"
                    name="Contact"
                    load={loadData}
                    onParamsChange={onParamsChange}
                    columns={contactColumns}
                    className={classes.tableContent}
                    classNames={{
                        root: classes.table,
                        contentWrapper: classes.tableContentWrapper,
                    }}
                    onRowClick={onRowClick}
                />
            </Card>
        </div>
    )
}

Contacts.defaultProps = defaultProps

export default Contacts
