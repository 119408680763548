import React, {
    useMemo,
} from 'react'

import {
    Navigate,
} from 'react-router-dom'
import {
    CommonRoutes,
    SkyNetRouteConfigObject,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'

import PreBookings from 'app/Apps/PreBooking'
import {
    AccountManagementDomains,
} from './AccountManagement.types'
import KamDashboard from './KamDashboard'
import Accounts from './Accounts'
import RegionalGroups from './RegionalGroups'
import Opportunities from './Opportunities'
import AccountLanes from './AccountLanes'
import AccountQuotes from './AccountQuotes'
import useAccountManagementRoutes from './hooks/useAccountManagementRoutes'

export default function AccountManagement() {
    const Routes = useAccountManagementRoutes()
    const routesConfig: SkyNetRouteConfigObject<AccountManagementDomains> = useMemo(() => {
        return {
            [Routes.KamDashboard.route]: <KamDashboard />,
            [Routes.Accounts.route]: <Accounts />,
            [Routes.RegionalGroups.route]: <RegionalGroups />,
            [Routes.Opportunities.route]: <Opportunities />,
            [Routes.Lanes.route]: <AccountLanes />,
            [Routes.Quotes.route]: <AccountQuotes />,
            [Routes.PreBookings.route]: <PreBookings />,
            [CommonRoutes.SLASH]: <Navigate to={Routes.KamDashboard.route} />,
        }
    }, [Routes])

    return useSkyNetRoutes({
        routesConfig,
    })
}
