import React, {
    useCallback,
} from 'react'
import AddIcon from '@mui/icons-material/Add'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import Button from 'app/shared-components/Button'

import useStyles from './Pooling.style'
import directLeaseConfig from './configs/directLease.config'
import internalTransportsConfig from './configs/internalTransports.config'
import containerSelectionConfig from './configs/containerSelection.config'
import airlineLeaseConfig from './configs/airlineLease.config'
import pickupAddInfoConfig from './configs/pickupAddInfo.config'
import pickupAddPickUpConfig from './configs/pickupAddPickUp.config'
import pickupDeliveryConfirmationConfig from './configs/pickupDeliveryConfirmation.config'
import transportDepartureConfig from './configs/transportDeparture.config'
import transportArrivalConfig from './configs/transportArrival.config'
import deliveryAddInfoConfig from './configs/deliveryAddInfo.config'
import deliveryAddPickupConfig from './configs/deliveryAddPickup.config'
import deliveryConfirmationConfig from './configs/deliveryConfirmation.config'
import preBookings from './configs/preBookings.config'
import useOrderManagementRoutes from '../hooks/useOrderManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Pooling = () => {
    const Routes = useOrderManagementRoutes()
    const {
        classes,
    } = useStyles()

    const openCreateForm = useCallback(
        ({
            collectionDropoffPointId, selectedPreviousOrderId,
        }): void => {
            Routes.InternalOrders.Create.navigate({
                options: {
                    state: {
                        collectionDropoffPointId,
                        selectedPreviousOrderId,
                    },
                },
            })
        },
        [Routes],
    )

    const onRowClick = useCallback(
        (
            name: string,
            {
                orderNumber,
            }: {
          orderNumber: string;
        },
        ): void => {
            if (name === 'customerTransports') {
                Routes.CustomerOrders.OrderSteps.navigate({
                    params: {
                        key: orderNumber,
                    },
                })
                return
            }
            if (name === 'internalTransports') {
                Routes.InternalOrders.OrderSteps.navigate({
                    params: {
                        key: orderNumber,
                    },
                })
                return
            }
            if (name === 'containerSelection') {
                Routes.InternalOrders.ContainerSelection.navigate({
                    params: {
                        key: orderNumber,
                    },
                })
            }
        },
        [Routes],
    )

    const onRowClickPreBookings = ({
        id,
    }: { id: number }): void => {
        Routes.PreBookings.Overview.navigate({
            params: {
                key: id,
            },
        })
    }

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Container Selection
                <div className={classes.actionWrapper}>
                    <Button
                        label="Create Internal Order"
                        onClick={openCreateForm}
                        data-testid="openCreateFormBtn"
                        icon={<AddIcon />}
                    />
                </div>
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingContainerSelection}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('containerSelection', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingContainerSelection
                    }
                    tableConfig={containerSelectionConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>Pre Bookings</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.PreBookingsTransports}
                    tableControls={tableControls}
                    onRowClick={onRowClickPreBookings}
                    customUrl={orderManagementRequest.Pooling.url.preBookings}
                    tableConfig={preBookings}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                    data-testid="preBookingsTransports"
                />
            </div>
            <div className={classes.headerTitle}>Customer Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('customerTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingCustomerTransport
                    }
                    tableConfig={directLeaseConfig(openCreateForm)}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('customerTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingCustomerTransport
                    }
                    tableConfig={airlineLeaseConfig(openCreateForm)}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>Internal Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddInfo}
                    tableConfig={pickupAddInfoConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddPickup}
                    tableConfig={pickupAddPickUpConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.pickupDeliveryConfirmation
                    }
                    tableConfig={pickupDeliveryConfirmationConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportDeparture}
                    tableConfig={transportDepartureConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportArrival}
                    tableConfig={transportArrivalConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddInfo}
                    tableConfig={deliveryAddInfoConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddPickup}
                    tableConfig={deliveryAddPickupConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryConfirmation}
                    tableConfig={deliveryConfirmationConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>Open Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingInternalTransports
                    }
                    tableConfig={internalTransportsConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
        </div>
    )
}

export default Pooling
