import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton, useArchive, ArchiveConfirmationDialog,
} from 'app/shared-components/Archive'

import {
    generateSkyNetRoute,
} from 'app/SkyNetRoutes'
import ActivityRecordForm from '../ActivityRecordForm'
import useGetActivityRecord from '../../hooks/useGetActivityRecord'
import request from '../ActivityRecords.requests'
import useRelationshipsManagementRoutes from '../../hooks/useRelationshipsManagementRoutes'

const ActivityRecordUpdate = ({
    id,
}: { id: number }) => {
    const {
        ActivityRecords: ActivityRecordsRoutes,
    } = useRelationshipsManagementRoutes()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetActivityRecord(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? request.restore
            : request.archive,
        notificationId: 'ActivityRecordUpdate',
        title: 'Activity Record',
        data,
        redirectionAfterSuccess: generateSkyNetRoute({
            domainPath: ActivityRecordsRoutes.path,
        }),
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        archive,
        data?.archived,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <ActivityRecordForm
                    requestParam={request.update(id)}
                    data={data}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Activity Record"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

export default ActivityRecordUpdate
