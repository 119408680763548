import {
    useMemo,
} from 'react'
import useSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useSkyNetDomainRoutes'
import {
    App,
    CommonRoutes,
    createRoutesConfig,
    useSkyNetNavigate,
} from 'app/SkyNetRoutes'

export default function useDeploymentRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useSkyNetDomainRoutes()

    return useMemo(() => {
        const config = {
            path: [],
            Deployments: {
                route: App.DEPLOYMENTS,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
