import {
    Contact,
} from 'app/types/common.types'
import {
    Currency,
    LeaseType,
    OrderTemperatureRange,
    LaneStatus,
    NotificationTypes,
    NotificationRecipientType,
    LaneGroupTemperatureRange,
} from 'app/types/enums'
import {
    Location,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import {
    Account,
} from 'app/Apps/AccountManagement/Accounts/Account.types'

export enum DeliveryServiceType {
    DELIVERY_ROAD = 'DELIVERY_ROAD',
    PICKUP = 'PICKUP'
}

export enum CollServiceType {
    COLLECTION = 'COLLECTION',
    DROPOFF_AIRPORT = 'DROPOFF_AIRPORT',
    DROPOFF_FORWARDER = 'DROPOFF_FORWARDER',
    DROPOFF_SKYCELL = 'DROPOFF_SKYCELL',
}

export enum TransportModeRoad {
    FTL = 'FTL',
    FTL_OR_LTL = 'FTL_OR_LTL',
    LTL = 'LTL',
}

export enum HandoverTime {
    TIME_0000 = 'TIME_0000',
    TIME_0100 = 'TIME_0100',
    TIME_0200 = 'TIME_0200',
    TIME_0300 = 'TIME_0300',
    TIME_0400 = 'TIME_0400',
    TIME_0500 = 'TIME_0500',
    TIME_0600 = 'TIME_0600',
    TIME_0700 = 'TIME_0700',
    TIME_0800 = 'TIME_0800',
    TIME_0900 = 'TIME_0900',
    TIME_1000 = 'TIME_1000',
    TIME_1100 = 'TIME_1100',
    TIME_1200 = 'TIME_1200',
    TIME_1300 = 'TIME_1300',
    TIME_1400 = 'TIME_1400',
    TIME_1500 = 'TIME_1500',
    TIME_1600 = 'TIME_1600',
    TIME_1700 = 'TIME_1700',
    TIME_1800 = 'TIME_1800',
    TIME_1900 = 'TIME_1900',
    TIME_2000 = 'TIME_2000',
    TIME_2100 = 'TIME_2100',
    TIME_2200 = 'TIME_2200',
    TIME_2300 = 'TIME_2300',
}

export enum IncoTerm {
    EXW = 'EXW',
    FCA = 'FCA',
    FAS = 'FAS',
    FOB = 'FOB',
    CFR = 'CFR',
    CIF = 'CIF',
    CPT = 'CPT',
    CIP = 'CIP',
    DAP = 'DAP',
    DPU = 'DPU',
    DDP = 'DDP',
}

export enum PreliminaryInvoiceCurrency {
    CHF = 'CHF',
    EUR = 'EUR',
    TWD = 'TWD',
    USD = 'USD',
}

export enum NotificationsOptions {
    ALL_ACTIVATED = 'ALL_ACTIVATED',
    ALL_DEACTIVATED = 'ALL_DEACTIVATED',
    ADVANCED_SETTINGS_ENABLED = 'ADVANCED_SETTINGS_ENABLED',
}

export type HandoverPoint = {
    id: number,
    timeZone: string,
    city: string,
}

export type NotificationsAdvancedSettingType = {
    disabled: boolean,
    triggerType: string,
    notificationName: string,
    notificationType: NotificationTypes,
    recipientTypes: NotificationRecipientType[]
}

export type NotificationsSettingsType = {
    settingsMode: NotificationsOptions,
    notificationsAdvancedSettings?: NotificationsAdvancedSettingType[]
}

export type InvoiceSettingsType = {
    incoTermAdditionalText: string,
    hsCodeContainer: string,
    hsCodeLogger: string,
    invoiceFootNote: string,
    consigneeAddress: string,
    scheduleBNumber: string,
}

export type Lane = {
    currency: Currency,
    laneStatus: LaneStatus,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    customerQuotationContacts: Contact[],
    consigneeContacts: Contact[],
    customerExecutionContactsOps: Contact[],
    collectionDropoffPointContacts: Contact[],
    handoverPointContacts: Contact[],
    transportModeRoad: TransportModeRoad,
    handoverEarliest: HandoverTime,
    handoverLatest: HandoverTime,
    originScContacts: Contact[],
    originScBackupContacts: Contact[],
    fwdPosBackupContacts: Contact[],
    fwdCustomsContacts: Contact[],
    fwdExportContacts: Contact[],
    fwdPosContacts: Contact[],
    fwdExportBackupContacts: Contact[],
    handoverPoint: HandoverPoint,
    tempRange: OrderTemperatureRange,
    account: Account,
    leaseType: LeaseType,
    originScBackup: {id: number},
    customerExecutionId: number,
    customerExecution: {id: number},
    customerExecutionContactsOpsIds: number[],
    customerQuotationId: number,
    customerQuotationContactsIds: number[],
    consigneeProvisional: string,
    consigneeLocationName: string,
    consigneeId: number,
    consignee: {
        id: number,
        city: string,
    },
    consigneeContactsIds: number[],
    destAirportId: number,
    destinationAirport: {
        id: number,
        locationName: string,
    },
    collectionDropoffPoint: {
        id: number,
        city: string,
    },
    collectionDropoffPointContactsIds: number[],
    originAirport: {
        id: number,
        locationName: string,
    },
    handoverPointContactsIds: number[],
    originSc: {
        id: number
    },
    containerSelectionMax: number,
    fwdPos: {
        id: number,
    },
    fwdPosBackup: {
        id: number,
    },
    fwdCustoms: {id: number},
    fwdExport: {id: number},
    fwdExportBackup: {id: number},
    orderInstructions: string,
    postponementPossibleUntil: number,
    endOfLeaseReminderDelay: number,
    laneCommentOps: string,
    reqCustomsCoord: boolean,
    incoTerm: IncoTerm,
    validPricingTo: string,
    minOrder: number,
    laneNumber: string,
    activateNotifications: boolean,
    id: number,
    createdBy: string,
    expPalletsYear: number,
    keyAccountManager: Contact,
    businessDevelopers: Contact[],
    customerQuotation: Location,
    preliminaryInvoiceCurrency: PreliminaryInvoiceCurrency,
    isLtlPositioningTemperatureControlled: boolean,
    isFtlPositioningTemperatureControlled: boolean,
    invoiceSettings: InvoiceSettingsType,
}

export type LaneOpsOverview = {
    laneNumber: string,
    leaseType: LeaseType,
    customerQuotationLocationName: string,
    customerExecutionLocationName: string,
    pricingAccountCompanyName: string,
    originAirportIataCode: string,
    destAirportIataCode: string,
    tempRange: LaneGroupTemperatureRange
    handoverPointLocationName: string,
    consigneeLocationName: string,
    deliveryServiceType: DeliveryServiceType,
    collectionServiceType: CollServiceType,
    validPricingTo: string,
    laneStatus: LaneStatus,
}

export type PropsTabs = {
    data?: Lane,
    onSuccess: () => void
}

export enum LanesTabs {
    LaneImplementation = 'laneimplementation',
    Notifications = 'notifications'
}
