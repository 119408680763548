import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LocationType,
    LaneGroupTemperatureRange,
    OpportunityFrequency,
    OpportunityStatus,
    RegionFirstLevel,
} from 'app/types/enums'
import {
    TypeIcon,
} from 'app/shared-components/NotificationIcon/notificationIcon.types'
import getContainerInformationFields from '../OpportunitiesContainerInformation/OpportunitiesContainerInformation.fields'
import {
    ReasonForWonLost,
    OpportunityType,
} from '../opportunities.types'

const getFields = (
    {
        getAllowedValues,
        originRegion,
        destinationRegion,
        disableRegionalGroup,
        disabled,
        showContainerInformation,
        accountId,
        accountCollaborators,
    }: {
        getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
        originRegion: RegionFirstLevel,
        destinationRegion: RegionFirstLevel,
        disableRegionalGroup: boolean,
        disabled: boolean,
        showContainerInformation: boolean,
        accountId: number[],
        accountCollaborators: number[]
    },
): DTOCardFormField<keyof OpportunityType>[] => {
    return [
        {
            id: 'generalInformation',
            title: 'General Information',
            className: 'generalInformation',
            fields: [
                {
                    id: 'column1',
                    fields: [
                        {
                            name: 'opportunityNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'Opportunity.opportunityNumber',
                            disabled: true,
                        },
                        {
                            name: 'regionalGroup',
                            componentName: 'RegionalGroupSelector',
                            labelKey: 'RegionalGroups.regionalGroupNumber',
                            disabled: disabled || disableRegionalGroup,
                            required: true,
                            filters: {
                                accountId,
                            },
                        },
                        {
                            name: 'accountName',
                            componentName: 'InputSingleline',
                            labelKey: 'Opportunities.accountName',
                            disabled: true,
                        },
                        {
                            name: 'opportunityStatus',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OpportunityStatus),
                            labelKey: 'AccountQuotes.laneStatus',
                            disabled: true,
                            useDropdown: true,
                        },
                        {
                            name: 'reasonForWonLost',
                            componentName: 'MultiEnumSelector',
                            allowedValues: getAllowedValues(ReasonForWonLost),
                            useDropdown: true,
                            labelKey: 'Opportunities.reasonForWonLost',
                        },
                        {
                            name: 'collaborators',
                            componentName: 'MultipleUserSelector',
                            labelKey: 'Opportunity.opportunityCollaborators',
                            includeFilters: {
                                id: accountCollaborators,
                            },
                            notificationIconProps: {
                                text: 'Only Account Collaborators can be added \n'
                                    + 'as Opportunity Collaborators',
                                type: TypeIcon.INFO,
                            },
                        },
                    ],
                },
                {
                    id: 'column2',
                    fields: [
                        {
                            name: 'temperatureRange',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                            useDropdown: true,
                            required: true,
                            disabled: true,
                            labelKey: 'Opportunities.temperatureRange',
                        },
                        {
                            name: 'frequency',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(OpportunityFrequency),
                            useDropdown: true,
                            labelKey: 'Opportunity.frequency',
                        },
                        {
                            name: 'airline',
                            componentName: 'CompanyObjectSelector',
                            labelKey: 'Opportunity.airline',
                        },
                        {
                            name: 'businessStart',
                            componentName: 'DateTimeSelect',
                            labelKey: 'Opportunity.businessStart',
                        },
                        {
                            name: 'opportunityComment',
                            componentName: 'LongText',
                            labelKey: 'Opportunity.opportunityComment',
                        },
                    ],
                },
            ],
        },
        {
            id: 'origin',
            title: 'Origin / Destination',
            className: 'origin',
            fields: [{
                id: 'column1',
                fields: [
                    {
                        name: 'originRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.originRegion',
                        disabled: true,
                    },
                    {
                        name: 'reprOriginAirport',
                        componentName: 'LocationObjectSelector',
                        filterLocationType: LocationType.AIRPORT,
                        includeFilters: {
                            regionFirstLevel: originRegion ? [originRegion] : undefined,
                        },
                        labelKey: 'Opportunity.reprOriginAirport',
                        required: true,
                        disabled: disabled || !originRegion,
                    },
                    {
                        name: 'destinationRegion',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(RegionFirstLevel),
                        labelKey: 'RegionalGroups.destinationRegion',
                        disabled: true,
                    },
                    {
                        name: 'reprDestinationAirport',
                        componentName: 'LocationObjectSelector',
                        includeFilters: {
                            regionFirstLevel: destinationRegion ? [destinationRegion] : undefined,
                        },
                        filterLocationType: LocationType.AIRPORT,
                        labelKey: 'Opportunity.reprDestinationAirport',
                        required: true,
                        disabled: disabled || !destinationRegion,
                    },
                ],
            }],
        },
        showContainerInformation ? getContainerInformationFields({
            getAllowedValues,
        }) : null,
    ].filter(Boolean) as DTOCardFormField<keyof OpportunityType>[]
}

export default getFields
