import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'
import {
    generateSkyNetRoute,
} from 'app/SkyNetRoutes'
import useGetServiceCenterById from '../hooks/useGetServiceCenterById'
import ServiceCenterForm from '../ServiceCenterForm'
import requests from '../serviceCenters.request'
import useContainerManagementRoutes from '../../hooks/useContainerManagementRoutes'

const ServiceCenterUpdate = ({
    id,
}: {id: number}) => {
    const {
        ServiceCenters: ServiceCentersRoutes,
    } = useContainerManagementRoutes()
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetServiceCenterById(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? requests.Restore
            : requests.Archive,
        notificationId: 'ServiceCenter',
        title: 'Service Center',
        data,
        redirectionAfterSuccess: generateSkyNetRoute({
            domainPath: ServiceCentersRoutes.Overview.path,
            params: {
                key: id,
            },
        }),
        onSuccess: invalidate,
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        data,
        archive.handleArchiveRestore,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady && Boolean(data)}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <ServiceCenterForm
                    data={data}
                    customButtons={customButtons}
                    exists
                />
                <ArchiveConfirmationDialog
                    title="Service Center"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>
    )
}

export default ServiceCenterUpdate
