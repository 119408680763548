import React, {
    useCallback,
} from 'react'

import useFilter from 'app/hooks/useFilter'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import AccountLaneSpreadsheet from '../AccountLaneSpreadsheet'
import useAccountLaneBulkCreate from './hooks/useAccountLaneBulkCreate'
import {
    AccountLane,
} from '../AccountLanes.types'

const AccountLaneBulkCreate = ({
    onClose,
}: {
    onClose: () => void
}) => {
    const filterOptions = useFilter()

    const bulkCreate = useAccountLaneBulkCreate()

    const handleSave = useCallback((values: AccountLane[], options?: AsyncMutationOptions) => {
        bulkCreate(values, {
            ...options,
            onSuccess: options?.onSuccess || onClose,
        })
    }, [
        bulkCreate,
        onClose,
    ])

    return (
        <AccountLaneSpreadsheet
            filterOptions={filterOptions}
            onSave={handleSave}
            onCancel={onClose}
        />
    )
}

export default AccountLaneBulkCreate
