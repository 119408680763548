import React, {
    useCallback,
    useEffect,
    useState,
} from 'react'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'

import useMakeRequestWithStatusHandlers from 'app/hooks/useMakeRequestWithStatusHandlers'
import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import Button from 'app/shared-components/Button'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import {
    SelectedContainer,
} from 'app/Apps/OrderManagement/Orders/orders.types'

import tableConfig from './AvailableContainers.config'
import useStyles from './AvailableContainers.styles'
import getSelectedContainers from './getSelectedContainers'

type Props = {
    location?: number | string,
    selectedContainers?: SelectedContainer[],
    requiredContainers?: Record<string, any>[],
    exists: boolean,
    orderNumber?: string,
    orderVersion?: number,
    disabled?: boolean,
    onSuccess?: (...args: any) => void,
    isInternalType?: boolean,
    isPreSelectContainers?: boolean,
}

const defaultProps = {
    location: undefined,
    orderNumber: undefined,
    orderVersion: undefined,
    disabled: false,
    isInternalType: false,
    isPreSelectContainers: true,
    requiredContainers: [],
    selectedContainers: [],
    onSuccess: noop,
}

const AvailableContainers = ({
    location,
    isInternalType,
    selectedContainers,
    isPreSelectContainers,
    requiredContainers,
    orderNumber,
    orderVersion,
    onSuccess,
    disabled,
    exists,
}: Props) => {
    const {
        classes,
    } = useStyles()

    const [
        value,
        onChange,
    ] = useState([])

    const [
        derivedLocation,
        setLocation,
    ] = useState(location)

    const [
        previousSelected,
        setPreviousSelected,
    ] = useState()

    const [
        containers,
        setContainers,
    ] = useState([])

    useEffect(() => {
        if (derivedLocation !== location) {
            onChange([])
            setLocation(location)
        }
    }, [
        derivedLocation,
        location,
        onChange,
    ])

    const onSelect = useCallback((selectedIds) => {
        onChange(selectedIds)
    }, [onChange])

    const addContainersApi = useMakeRequestWithStatusHandlers(
        orderManagementRequest.AddSelectedAssets.request(onSuccess),
    )

    const addToBookedContainers = useCallback(() => {
        return addContainersApi({
            data: {
                containers: value.map((containerId) => {
                    return {
                        container: {
                            id: containerId,
                        },
                    }
                }),
                version: orderVersion,
            },
            url: orderManagementRequest.AddSelectedAssets.url(orderNumber),
        })
    }, [
        addContainersApi,
        orderNumber,
        orderVersion,
        value,
    ])

    const onLoad = useCallback(({
        data,
    }) => {
        setContainers(data)
    }, [])

    useEffect(() => {
        // preselection does not support product types (CT)
        if (!isPreSelectContainers || !isInternalType
            || (!containers?.length || !requiredContainers?.length)) {
            return
        }

        const preSelectedContainers = getSelectedContainers(
            requiredContainers,
            selectedContainers,
            containers,
        )

        if (!isEqual(preSelectedContainers, previousSelected)) {
            setPreviousSelected(preSelectedContainers)
            onSelect(preSelectedContainers)
        }
    },
    [
        containers,
        onSelect,
        previousSelected,
        selectedContainers,
        requiredContainers,
        isPreSelectContainers,
        isInternalType,
    ])

    return (
        <>
            {location !== undefined ? (
                <SkyNetTable
                    name="ContainerView"
                    selectedRows={value}
                    onSelectRow={!disabled && onSelect}
                    simple
                    customUrl={orderManagementRequest.GetAvailableContainers.url(orderNumber)}
                    method={orderManagementRequest.GetAvailableContainers.request.method}
                    className={classes.root}
                    onLoad={onLoad}
                    rowsPerPageDefault={250}
                    tableConfig={tableConfig}
                    showSearch
                />
            ) : (
                <div className={classes.emptyMsg}>
                    Please select a location.
                </div>
            )}
            {location !== undefined && exists && !disabled && (
                <div className={classes.buttonContainer}>
                    <Button
                        label="add to booked containers"
                        name="add"
                        disabled={!value.length}
                        onClick={addToBookedContainers}
                    />
                </div>
            )}
        </>
    )
}

AvailableContainers.defaultProps = defaultProps

export default AvailableContainers
