import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
    getUrlsFromTableConfig,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import useTabsByPermission from 'app/hooks/useTabsByPermission'
import EngagementCreateForm from './EngagementCreateForm'
import EngagementOverview from './EngagementOverview'
import EngagementUpdate from './EngagementUpdate'
import getConfig from './engagement.config'
import useRelationshipsManagementRoutes from '../hooks/useRelationshipsManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.Search,
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Engagement = () => {
    const {
        Engagements: EngagementsRoutes,
    } = useRelationshipsManagementRoutes()
    const canCreate = useHasPermission(['relationships_sales'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: EngagementsRoutes.Overview.route,
                Component: EngagementOverview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Data Update',
                url: EngagementsRoutes.Update.route,
                Component: EngagementUpdate,
            },
            permission: true,
        },
    ])

    const tableConfig = useMemo(() => {
        return getConfig()
    }, [])

    return (
        <SkyNetDomain
            actionComponents={{
                Create: canCreate ? EngagementCreateForm : null,
            }}
            sidebarTabs={tabs}
            defaultTab={EngagementsRoutes.Overview.route}
            name="Engagement"
            subdomainUrls={getUrlsFromTableConfig(tableConfig)}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
            />
        </SkyNetDomain>

    )
}

export default Engagement
