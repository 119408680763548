import React from 'react'

import {
    CommonRoutes, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import AlLeasingPricingUpdate from '../AlLeasingPricingUpdate'
import AlLeasePricingCopy from '../AlLeasePricingCopy'
import usePricingRoutes from '../../hooks/usePricingRoutes'

type Props = {
    id: number,
}

const AlLeasePricingOverview = ({
    id,
}: Props) => {
    const {
        AlLeasePricing: AlLeasePricingRoutes,
    } = usePricingRoutes()

    return useSkyNetRoutes({
        routes: [
            {
                path: CommonRoutes.SLASH,
                element: <AlLeasingPricingUpdate id={id} />,
            },
            {
                path: AlLeasePricingRoutes.Copy.stringParams,
                element: <AlLeasePricingCopy id={id} />,
            },
        ],
    })
}

export default AlLeasePricingOverview
