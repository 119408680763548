import {
    useMemo,
} from 'react'
import {
    useSkyNetNavigate, SkyNetRoutesConfig, App, CommonRoutes, createRoutesConfig,
} from 'app/SkyNetRoutes'
import useSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useSkyNetDomainRoutes'
import {
    AccountQuotesTab,
} from 'app/Apps/AccountManagement/AccountQuotes/AccountQuotes.types'
import {
    PricingDomains,
} from '../Pricing.types'
import {
    ContractBasisTabs,
} from '../ContractBasis/ContractBasis.types'
import {
    LaneGroupsTabs,
} from '../LaneGroups/LaneGroups.types'
import {
    AssingPricingSubRoutes,
} from '../../AccountManagement/AccountQuotes/AssignPricing/AssingPricing.types'

export default function usePricingRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useSkyNetDomainRoutes()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.PRICING],
            PricingDashboard: {
                route: PricingDomains.PricingDashboard,
            },
            ContractBasis: {
                route: PricingDomains.ContractBasis,
                ContractBasis: {
                    route: ContractBasisTabs.ContractBasis,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                AdditionalFees: {
                    route: ContractBasisTabs.AdditionalFees,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'id',
                    }],
                },
                AdditionalFeesCopy: {
                    route: ContractBasisTabs.AdditionalFees,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [
                        {
                            name: 'id',
                            required: true,
                        },
                        {
                            name: 'action',
                        },
                    ],
                },
                LaneGroups: {
                    route: ContractBasisTabs.LaneGroups,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                CreateQuote: {
                    route: ContractBasisTabs.CreateQuote,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            LaneGroups: {
                route: PricingDomains.LaneGroups,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'copy',
                    }],
                },
                Copy: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'copy',
                    }],
                },
                Lanes: {
                    route: LaneGroupsTabs.Lanes,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [
                        {
                            name: 'actionId',
                        },
                        {
                            name: 'action',
                        },
                    ],
                },
                LaneFees: {
                    route: LaneGroupsTabs.LaneFees,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ChangeHistory: {
                    route: CommonRoutes.CHANGE_HISTORY,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
            Lanes: {
                route: PricingDomains.Lanes,
            },
            AlLogisticPricing: {
                route: PricingDomains.AlLogisticPricing,
                Update: {
                    route: CommonRoutes.UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Copy: {
                    route: CommonRoutes.UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'copy',
                    }],
                },
            },
            AlLeasePricing: {
                route: PricingDomains.AlLeasePricing,
                Update: {
                    route: CommonRoutes.UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Copy: {
                    route: CommonRoutes.UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'copy',
                    }],
                },
            },
            Quotes: {
                route: PricingDomains.Quotes,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                AssignQuote: {
                    route: AccountQuotesTab.ASSIGN_QUOTE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                ReviewGroup: {
                    route: AccountQuotesTab.REVIEW_GROUP,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                AssignPricing: {
                    route: AccountQuotesTab.ASSIGN_PRICING,
                    via: SkyNetDomainRoutes.All.Edit,
                    params: [{
                        name: 'laneGroupId',
                    }],
                    LaneFees: {
                        route: AssingPricingSubRoutes.LaneFees,
                        params: [
                            {
                                name: 'feesId',
                            },
                            {
                                name: 'feesAction',
                            },
                        ],
                    },
                },
                AwardReject: {
                    route: AccountQuotesTab.AWARD_REJECT,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                QuotePdf: {
                    route: AccountQuotesTab.QUOTE_PDF,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
