import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import useHasPermission from 'app/hooks/useHasPermission/useHasPermission'
import Overview from './Overview'
import tableConfig from './regionalGroups.config'
import request from './regionalGroups.request'
import UpdateData from './UpdateData'
import Opportunities from './Opportunities'
import RegionalGroupCreate from './RegionalGroupCreate'
import useAccountManagementRoutes from '../hooks/useAccountManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const RegionalGroups = () => {
    const AccountManagementDomains = useAccountManagementRoutes()
    const updatePermission = useHasPermission([
        'account_update_all',
        'account_update_restricted',
    ])
    const canCreateRegionGroup = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                label: 'Overview',
                url: AccountManagementDomains.RegionalGroups.Overview.route,
                Component: Overview,
            },
            permission: true,
        },
        {
            tab: {
                label: 'Update Data',
                url: AccountManagementDomains.RegionalGroups.Update.route,
                Component: UpdateData,
            },
            permission: updatePermission,
        },
        {
            tab: {
                label: 'Opportunities',
                url: AccountManagementDomains.RegionalGroups.Opportunities.route,
                Component: Opportunities,
            },
            permission: true,
        },
    ])

    return (
        <SkyNetDomain
            sidebarTabs={tabs}
            defaultTab="overview"
            name={tableConfig.name}
            actionComponents={{
                Create: canCreateRegionGroup ? RegionalGroupCreate : undefined,
            }}
        >
            <SkyNetTable
                moduleName={request.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default RegionalGroups
