import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import {
    ArchiveButton, ArchiveConfirmationDialog, useArchive,
} from 'app/shared-components/Archive'

import {
    generateSkyNetRoute,
} from 'app/SkyNetRoutes'
import DeploymentRequests from '../Deployments.request'
import DeploymentForm from '../DeploymentForm'
import useGetDeployment from '../hooks/useGetDeployment'
import useDeploymentRoutes from '../hooks/useDeploymentRoutes'

const defaultProps = {
    disabled: false,
}

const DeploymentUpdate = ({
    id,
    disabled,
}: {
    id: number,
    disabled?: boolean,
}) => {
    const {
        Deployments: DeploymentsRoutes,
    } = useDeploymentRoutes()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        invalidate,
        error,
    } = useGetDeployment(id)

    const archive = useArchive({
        requestParamFn: data?.archived ? DeploymentRequests.restore
            : DeploymentRequests.archive,
        notificationId: 'Deployment',
        title: 'Deployment',
        data,
        redirectionAfterSuccess: generateSkyNetRoute({
            domainPath: DeploymentsRoutes.path,
        }),
        onSuccess: invalidate,
    })

    const customButtons = useMemo(() => {
        return [<ArchiveButton
            onClick={archive.handleArchiveRestore}
            archived={data?.archived}
        />]
    }, [
        data,
        archive.handleArchiveRestore,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <DeploymentForm
                    data={data}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    requestParam={DeploymentRequests.update.request({
                        id,
                    })}
                    disabled={disabled}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Deployment"
                    openConfirmDialog={archive.openConfirmDialog}
                    handleClose={archive.handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
            </>
        </StatusHandler>

    )
}

DeploymentUpdate.defaultProps = defaultProps

export default DeploymentUpdate
