import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useRequestTable from 'app/hooks/useRequestTable'
import AdditionalFeesRequests from '../AdditionalFees.requests'

export default function useGetAdditionalFeesByContractBasisId({
    id, showArchived,
}: {
    id: number,
    showArchived: boolean
}) {
    const {
        onParamsChange,
        data,
        loadData,
        isDataReady,
        refetch,
    } = useRequestTable<{data:any}>({
        config: AdditionalFeesRequests.filterRequest,
        params: {
            contractId: id,
        },
        key: RequestKeys.getContractBasisAdditionalFee,
        showArchived,
    })

    return {
        onParamsChange,
        isDataReady,
        refetch,
        data,
        loadData,
    }
}
