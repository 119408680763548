/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useState,
    useCallback,
    useMemo,
} from 'react'

import {
    dayPassedToRange,
} from 'app/utils/date'
import useGetSensorDataForLogger from 'app/hooks/useGetSensorData/useGetSensorDataForLogger'
import {
    SENSOR_CODES_NAME,
} from 'app/utils/optionsLoggers'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    SensorCode,
    TemperatureRange,
} from 'app/types/enums'

import {
    ConfigLogger, LoggerDataTypes,
    LoggerResponse,
} from '../Loggers.types'
import Logger from './Logger'
import useOtherLoggers from '../hooks/useOtherLoggers'

export type LoggerContainerProps = {
    name: string,
    data?: LoggerResponse,
    selectedRowId?: string,
}

const SENSOR_CODES = 'Sensor Codes'

const defaultProps: Partial<LoggerContainerProps> = {
    data: {} as LoggerResponse,
}

const LoggerContainer = ({
    data,
    name,
}: LoggerContainerProps) => {
    const {
        loggerNumber,
        loggerType,
        allowedSensorCodes,
    } = data

    const [
        isDateRange,
        setIsDateRange,
    ] = useState(false)

    const [
        timeRange,
        setTimeRange,
    ] = useState(dayPassedToRange(7))

    const [
        showTempRange,
        onCheckShowTempRange,
    ] = useState(false)

    const [
        utcTime,
        setUtcTime,
    ] = useState(true)

    const [
        dataType,
        setDataType,
    ] = useState(LoggerDataTypes.TEMPERATURE)

    const [
        selectedTemperatureRange,
        onChangeSelectedTemperatureRange,
    ] = useState(TemperatureRange.CRT)

    const [
        temperatureChartFullscreen,
        setTemperatureChartFullscreen,
    ] = useState(false)

    const [
        showDoorEvent,
        onCheckShowDoorEvent,
    ] = useState(false)

    const {
        otherLoggers, onOtherLoggersChange,
    } = useOtherLoggers()

    const sensorCodesEnums = useEnumValues()(SensorCode)

    const getSensorCodeConfig = useCallback(({
        sensorCodes,
    }: {
        sensorCodes: SensorCode[],
    }): ConfigLogger => {
        return {
            title: SENSOR_CODES,
            name: SENSOR_CODES_NAME,
            sensorCodes,
            loggerType,
            options: sensorCodes.map((value) => {
                return {
                    value, label: sensorCodesEnums[value] || value,
                }
            }),
        }
    }, [
        loggerType,
        sensorCodesEnums,
    ])

    const sensorCodesConfig = useMemo(() => {
        const sensorCodes = new Set(allowedSensorCodes)

        return Array.from(otherLoggers.reduce((acc, {
            allowedSensorCodes: otherLoggersAllowedSensorCodes,
        }) => {
            otherLoggersAllowedSensorCodes.forEach((code) => {
                sensorCodes.add(code)
            })
            return sensorCodes
        }, sensorCodes))
    }, [
        otherLoggers,
        allowedSensorCodes,
    ])

    const [
        configLoggers,
        setConfigLoggers,
    ] = useState([getSensorCodeConfig({
        sensorCodes: sensorCodesConfig,
    })])

    const onChangeConfigLoggers = ([config]) => {
        setConfigLoggers([{
            ...config,
            sensorCodes: config?.options?.map(({
                value,
            }) => {
                return value
            }),
        }])
    }

    const {
        sensorData,
        isDataReady,
    } = useGetSensorDataForLogger({
        serialNumber: loggerNumber,
        from: timeRange.from,
        to: timeRange.to,
        configLoggers,
        otherLoggers,
        isDateRange,
        showDoorEvents: showDoorEvent,
        useStandardizedTimestamps: utcTime,
        dataTypes: [dataType],
    })

    return (
        <Logger
            {...sensorData}
            name={name}
            loggerInfo={data}
            timeRange={timeRange}
            setTimeRange={setTimeRange}
            showTempRange={showTempRange}
            onCheckShowTempRange={onCheckShowTempRange}
            selectedTemperatureRange={selectedTemperatureRange}
            temperatureChartFullscreen={temperatureChartFullscreen}
            setTemperatureChartFullscreen={setTemperatureChartFullscreen}
            onChangeSelectedTemperatureRange={onChangeSelectedTemperatureRange}
            configLoggers={configLoggers}
            setConfigLoggers={onChangeConfigLoggers}
            otherLoggers={otherLoggers}
            onOtherLoggersChange={onOtherLoggersChange}
            isDateRange={isDateRange}
            setDateRange={setIsDateRange}
            showDoorEvent={showDoorEvent}
            onCheckShowDoorEvent={onCheckShowDoorEvent}
            totalAllowedSensorValues={sensorCodesConfig}
            utcTime={utcTime}
            setUtcTime={setUtcTime}
            dataType={dataType}
            setDataType={setDataType}
            isDataReady={isDataReady}
        />
    )
}

LoggerContainer.defaultProps = defaultProps

export default LoggerContainer
