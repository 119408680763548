import {
    useCallback,
} from 'react'

import useOrderManagementRoutes from 'app/Apps/OrderManagement/hooks/useOrderManagementRoutes'
import {
    OrderTypes,
} from '../orders.types'

const useOnSuccessCreateOrder = (orderType: OrderTypes) => {
    const OrderManagementRoutes = useOrderManagementRoutes()

    return useCallback(({
        orderNumber,
    }) => {
        if (orderType === OrderTypes.CUSTOMER_TRANSPORT) {
            OrderManagementRoutes.CustomerOrders.Overview.navigate({
                params: {
                    key: orderNumber,
                },
            })
        }
        if (orderType === OrderTypes.INTERNAL_TRANSPORT) {
            OrderManagementRoutes.InternalOrders.Overview.navigate({
                params: {
                    key: orderNumber,
                },
            })
        }
    }, [
        OrderManagementRoutes,
        orderType,
    ])
}

export default useOnSuccessCreateOrder
