import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    ContactMainType,
} from 'app/types/enums'
import {
    CommonRoutes,
} from 'app/SkyNetRoutes'
import {
    Contact,
} from './Contacts.types'

type ContactTable = Contact & {
    locationLocationName: string,
    locationCompanyCompanyName: string
}

export enum ContactsTableTabs {
    Prospect = 'prospect'
}

const config = (): SkyNetTableConfig<keyof ContactTable> => {
    return {
        name: 'ContactsTable',
        tabs: [
            {
                name: 'Contacts',
                url: CommonRoutes.ALL,
                includeFilters: {
                    isProspect: [false],
                },
            },
            {
                name: 'Prospect Contacts',
                url: ContactsTableTabs.Prospect,
                requestUrl: 'prospect',
            },
        ],
        fields: [
            {
                name: 'contactName',
                filterField: 'contactName',
                reduced: true,
                labelKey: 'Contact.contactName',
            },
            {
                name: 'email',
                filterField: 'email',
                labelKey: 'Contact.email',
            },
            {
                name: 'phone',
                filterField: 'phone',
                labelKey: 'Contact.phone',
            },
            {
                name: 'isMainContact',
                componentName: 'ChipCheckMark',
                labelKey: 'Contact.isMainContact',
            },
            {
                name: 'locationLocationName',
                filterField: 'locationLocationName',
                labelKey: 'Contact.location',
            },
            {
                name: 'locationCompanyCompanyName',
                filterField: 'locationCompanyCompanyName',
                reduced: true,
                labelKey: 'Location.company',
            },
            {
                name: 'mainType',
                componentName: 'EnumList',
                allowedValuesEnum: ContactMainType,
                labelKey: 'Contact.mainType',
            },
            {
                name: 'createdBy',
                filterField: 'createdBy',
                labelKey: 'AccountQuotes.createdBy',
            },
        ],
    }
}

export default config
