import React from 'react'

import {
    CommonRoutes, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import AlLogisticPricingUpdate from '../AlLogisticPricingUpdate'
import AlLogisticPricingCopy from '../AlLogisticPricingCopy'
import usePricingRoutes from '../../hooks/usePricingRoutes'

type Props = {
    id: number,
}

const AlLogisticPricingOverview = ({
    id,
}: Props) => {
    const {
        AlLogisticPricing: AlLogisticPricingRoutes,
    } = usePricingRoutes()

    return useSkyNetRoutes({
        routes: [
            {
                path: CommonRoutes.SLASH,
                element: <AlLogisticPricingUpdate id={id} />,
            },
            {
                path: AlLogisticPricingRoutes.Copy.stringParams,
                element: <AlLogisticPricingCopy id={id} />,
            },
        ],
    })
}

export default AlLogisticPricingOverview
