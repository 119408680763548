import {
    NavigateFunction, RoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

// eslint-disable-next-line import/prefer-default-export
export function getDailyOpsNavigate({
    name, Route,
}: {
    name: string,
    Route: RoutesConfig
}): NavigateFunction {
    try {
        switch (name) {
        case 'toBeConfirmed': return Route.Overview.navigate
        case 'pqOrders': return Route.Overview.navigate
        case 'deliveryViaRoadInfo': return Route.OrderSteps.navigate
        case 'deliveryViaRoadAddPickup': return Route.OrderSteps.navigate
        case 'deliveryViaRoadConfirmation': return Route.OrderSteps.navigate
        case 'pickupViaRoadConfirmation': return Route.OrderSteps.navigate
        case 'airTransportByCustomerInfo': return Route.OrderSteps.navigate
        case 'confirmationOfDropOffInfo': return Route.OrderSteps.navigate
        case 'confirmationOfUnloadingInfo': return Route.OrderSteps.navigate
        case 'missingInformation': return Route.Overview.navigate
        case 'containerSelection': return Route.ContainerSelection.navigate
        default: return Route.Overview.navigate
        }
    } catch (e) {
        return Route.Overview.navigate
    }
}
