import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    return {
        buttonHeader: {
            backgroundColor: theme.palette.primary.light,
            height: 161,
        },
    }
})
