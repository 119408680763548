import React from 'react'
import PropTypes from 'prop-types'
import {
    Button,
} from '@mui/material'
import {
    Link,
} from 'react-router-dom'
import clsx from 'clsx'

import useStyles from './Layer.style'

const propTypes = {
    layerClasses: PropTypes.string,
    layerButtons: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
    layerClasses: '',
    layerButtons: [],
}

const Layer = ({
    layerClasses, layerButtons,
}) => {
    const {
        classes,
    } = useStyles()

    return (
        <div className={clsx(classes.layerContent, layerClasses)}>
            {layerButtons.map((but) => {
                return (
                    <Button
                        key={but.title}
                        data-testid={`layer-button-${but.id}`}
                        className={classes.button}
                        sx={{
                            textTransform: 'none !important',
                        }}
                    >
                        <Link
                            to={but.url}
                            className={classes.link}
                        >
                            {but.title}
                        </Link>
                    </Button>
                )
            })}
        </div>
    )
}

Layer.propTypes = propTypes
Layer.defaultProps = defaultProps

export default Layer
