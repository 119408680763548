import {
    generatePath,
    useNavigate,
} from 'react-router-dom'
import {
    useCallback,
} from 'react'
import {
    APPS,
    CommonRoutes, NavigateFunctionArgs,
} from '../SkyNetRoutes.types'

export default function useSkyNetNavigate() {
    const navigate = useNavigate()

    return useCallback((
        path: string[],
        navigateParams?: NavigateFunctionArgs,
    ) => {
        navigate(
            generatePath([
                CommonRoutes.SLASH,
                APPS,
                ...path,
            ].filter(Boolean).join('/'),
            navigateParams?.params),
            navigateParams?.options,
        )
    }, [navigate])
}
