import React from 'react'
import {
    Typography,
} from '@mui/material'

import useStyles from './SmallContent.style'

const defaultProps = {
    title: '',
    text: '',
}

export default function SmallContent({
    title, text,
}: Readonly<{
    title?: string,
    text?: string,
}>) {
    const {
        classes,
    } = useStyles()

    return (
        <div
            className={classes.button}
        >
            <Typography
                variant="h2"
                className={classes.title}
            >
                {title}
            </Typography>
            <Typography
                variant="body1"
                className={classes.text}
            >
                {text}
            </Typography>
        </div>
    )
}

SmallContent.defaultProps = defaultProps
