/* eslint-disable import/prefer-default-export */
import {
    SkyNetTableConfig,
} from '../SkyNetTable'

export function getUrlsFromTableConfig(tableConfig: SkyNetTableConfig) {
    return tableConfig?.tabs.map(({
        url,
    }) => {
        return url
    })
}
