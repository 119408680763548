import React, {
    ReactNode,
} from 'react'
import clsx from 'clsx'

import Menu from './Menu'
import Panel from './Panel'

import useStyles from './MainMenu.style'

const defaultProps = {
    className: '',
}

const MainMenu = ({
    children,
    className,
}: Readonly<{ children: ReactNode[], className?: string }>) => {
    const {
        classes,
    } = useStyles()

    return (
        <div
            className={clsx(classes.root, className)}
        >
            <div className={classes.header}>
                <Panel />
            </div>
            <div className={classes.main}>
                <div className={classes.menu}>
                    <Menu />
                </div>
                <div
                    className={classes.body}
                    id="mainScroll"
                >
                    {children}
                    <div
                        className={classes.sidebar}
                        id="sidebarContainer"
                    />
                </div>
            </div>
        </div>
    )
}

MainMenu.defaultProps = defaultProps

export default MainMenu
