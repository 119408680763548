import React, {
    useMemo,
} from 'react'
import {
    useUserRoles,
} from 'app/Auth'

import {
    ActiveTab as ActiveTabType,
} from 'app/MainMenu/MainMenu.types'

import ActiveTab from './ActiveTab'

import useSelectedTab from './hooks/useSelectedTab'

export default function ActiveTabContainer() {
    const {
        icon, links, className,
    } = useSelectedTab() as ActiveTabType

    const roles = useUserRoles()

    const allowedLinks = useMemo(() => {
        return links.filter(({
            auth,
        }) => {
            return auth.some((role) => {
                return roles.includes(role)
            })
        })
    }, [
        links,
        roles,
    ])

    return (
        <ActiveTab
            icon={icon}
            links={allowedLinks}
            className={className}
        />
    )
}
