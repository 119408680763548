import React, {
    useCallback,
} from 'react'
import {
    useBlocker,
} from 'react-router-dom'
import UnsavedChangesPrompt from '../UnsavedChangesPrompt'

const defaultProps = {
    promptText: 'Would you like to save your changes before you leave this page?',
    discard: undefined,
    positiveLabel: undefined,
    isPositiveAlert: false,
    action: undefined,
}

const HistoryBlockPrompt = ({
    when, action, promptText, discard, positiveLabel, isPositiveAlert,
}: {
    when: boolean,
    discard?: () => void,
    action?: (...a: any[]) => Promise<any> | any,
    promptText?: string,
    positiveLabel?: string,
    isPositiveAlert?: boolean,
}) => {
    const {
        proceed, state, reset,
    } = useBlocker(when)

    const handleAction = useCallback(async () => {
        try {
            if (action) {
                await action()
            }

            proceed()
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }, [
        action,
        proceed,
    ])

    const handleDiscard = useCallback(() => {
        discard()
        proceed()
    }, [
        proceed,
        discard,
    ])

    return (
        <UnsavedChangesPrompt
            action={handleAction}
            discard={discard ? handleDiscard : null}
            open={state === 'blocked'}
            close={reset}
            promptText={promptText}
            positiveLabel={positiveLabel}
            isPositiveAlert={isPositiveAlert}
        />
    )
}

HistoryBlockPrompt.defaultProps = defaultProps

export default HistoryBlockPrompt
