import useRequest, {
    RequestFn, RequestKeys,
} from 'app/hooks/useRequest'
import {
    AdditionalFeeValue,
} from '../AdditionalFee.types'
import AdditionalFeesRequests from '../AdditionalFees.requests'

export default function useGetAdditionalFeesById(id: number) {
    return useRequest<AdditionalFeeValue>({
        key: RequestKeys.getContractBasisAdditionalFeeDetails,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(AdditionalFeesRequests.get),
        enabled: Boolean(id),
    })
}
