import React from 'react'
import noop from 'lodash/noop'
import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequestConfig,
} from 'app/types/request.types'

import {
    Account,
} from 'app/Apps/AccountManagement/Accounts/Account.types'
import getAccountFields from '../Account.fields'
import AccountManagementRequests from '../../AccountManagement.request'

type Props = {
    data?: Account,
    onSuccess?: (props: any) => void,
    onCancel?: (...args: any[]) => void,
    requestParam: RequestConfig,
    disabled?: boolean,
    customButtons?: JSX.Element[],
}

const defaultProps: Partial<Props> = {
    data: {} as Account,
    onSuccess: noop,
    onCancel: noop,
    disabled: false,
    customButtons: [],
}

const AccountForm = ({
    data,
    onSuccess,
    onCancel,
    requestParam,
    disabled,
    customButtons,
}: Props) => {
    const getAllowedValues = useEnumValues()
    const exists = !!data?.id

    return (
        <FormWithControls
            onSuccess={onSuccess}
            value={data}
            name={AccountManagementRequests.domainName}
            fields={getAccountFields(getAllowedValues, exists)}
            requestParams={requestParam}
            exists={exists}
            onCancel={onCancel}
            disabled={disabled}
            customButtons={customButtons}
        />
    )
}

AccountForm.defaultProps = defaultProps

export default AccountForm
