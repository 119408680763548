import React, {
    useMemo,
} from 'react'
import useContractBasisContext from 'app/Apps/Pricing/ContractBasis/hooks/useContractBasisContext'
import {
    AdditionalFeesStatus,
    RangeType,
} from 'app/types/enums'
import AdditionalFeesForm from '../AdditionalFeesForm'
import AdditionalFeesRequests from '../AdditionalFees.requests'

export default function AdditionalFeesCreate({
    onSuccess, onCancel,
}: {
    onSuccess: (el: {id: number}) => void,
    onCancel: () => void,
}) {
    const {
        contractId,
    } = useContractBasisContext()

    const requestParams = useMemo(() => {
        return AdditionalFeesRequests.create({
            contractId,
        })
    }, [contractId])

    return (
        <AdditionalFeesForm
            value={{
                additionalFeesStatus: AdditionalFeesStatus.IN_PROGRESS,
                cancellationFeeTimeline: 5,
                cancellationDaysType: RangeType.CALENDAR_DAYS,
            }}
            onSuccess={onSuccess}
            onCancel={onCancel}
            requestParams={requestParams}
        />
    )
}
