import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    LoggerType,
} from 'app/types/enums'

const config: SkyNetTableConfig = {
    name: 'Gateway.ConnectedLoggers',
    fields: [
        {
            name: 'loggerNumber',
            labelKey: 'Logger.loggerNumber',
        },
        {
            name: 'loggerType',
            componentName: 'EnumValue',
            allowedValuesEnum: LoggerType,
            labelKey: 'Logger.loggerType',
        },
        {
            name: 'lastMeasuredOnUtc',
            componentName: 'TemperatureAge',
            labelKey: 'Logger.lastMeasuredOnUtc',
        },
    ],
}

export default config
