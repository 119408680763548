import {
    useMemo,
} from 'react'
import {
    App, CommonRoutes, useSkyNetNavigate,
} from 'app/SkyNetRoutes'
import {
    createRoutesConfig,
} from 'app/SkyNetRoutes/skyNetRoutes.utils'
import useSkyNetDomainRoutes from 'app/shared-components/SkyNetDomain/useSkyNetDomainRoutes'
import {
    SkyNetRoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'
import {
    FinanceDomains,
} from '../Finance.types'

export default function useFinanceRoutes() {
    const skyNetNavigate = useSkyNetNavigate()
    const SkyNetDomainRoutes = useSkyNetDomainRoutes()

    return useMemo(() => {
        const config: SkyNetRoutesConfig = {
            path: [App.FINANCE],
            Invoices: {
                route: FinanceDomains.Invoices,
            },
            FxRates: {
                route: FinanceDomains.FxRates,
                Overview: {
                    route: CommonRoutes.OVERVIEW,
                    via: SkyNetDomainRoutes.All.Edit,
                },
                Update: {
                    route: CommonRoutes.DATA_UPDATE,
                    via: SkyNetDomainRoutes.All.Edit,
                },
            },
        }

        return createRoutesConfig(skyNetNavigate)(config)
    }, [
        SkyNetDomainRoutes,
        skyNetNavigate,
    ])
}
