import React, {
    useCallback, useMemo, useReducer, useState,
} from 'react'
import {
    useJsApiLoader, Libraries,
} from '@react-google-maps/api'
import {
    Library,
} from 'app/types/googlemaps.types'

import FormWithControls from 'app/shared-components/FormWithControls'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useHasPermission from 'app/hooks/useHasPermission'
import {
    RolesTypes,
} from 'app/utils/hasPermission'
import {
    defaultTaxCodes,
} from 'app/config'

import reducer from 'app/Apps/ContactManagement/store/reducer'
import {
    setValue,
} from 'app/Apps/ContactManagement/store/actions'
import {
    LocationType,
} from 'app/types/enums'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequestConfig,
} from 'app/types/request.types'
import {
    BillingCompany,
} from '../BillingCompanyForm.types'

import useStyles from './BillingCompanyForm.styles'
import getBillingFormFields from './BillingCompanyForm.fields'

type BillingFormProps = {
    data?: BillingCompany,
    onSuccess: (...args: any[]) => void,
    onCancel: (...args: any[]) => void,
    request: RequestConfig,
    customButtons?: JSX.Element[]
}

const defaultProps: Partial<BillingFormProps> = {
    customButtons: [],
    data: {
        locationType: LocationType.ADDRESS,
    } as BillingCompany,
}

const libraries: Libraries = [Library.PLACES]
const RIGHTS_WITH_BILLING_PERMISSION: RolesTypes[] = ['location_bil_super']

const {
    REACT_APP_GOOGLE_MAP_KEY: googleMapKey,
} = process.env

const BillingCompanyForm = ({
    data,
    onSuccess,
    onCancel,
    request,
    customButtons,
}: BillingFormProps) => {
    const getAllowedValues = useEnumValues()
    const {
        isLoaded: isGoogleMapsServiceLoaded,
    } = useJsApiLoader({
        googleMapsApiKey: googleMapKey,
        libraries,
    })

    const isNew = !data?.id

    const [
        {
            value,
            timeZones,
        },
        dispatch,
    ]: [
        { value: BillingCompany, timeZones: [] },
        (args: { type: string, value: BillingCompany }) => void,
    ] = useReducer(reducer, {
        value: data,
        edited: false,
        timeZones: [],
    })

    const [
        isSyncedWithAbacusEdited,
        setIsSyncedWithAbacusEdit,
    ] = useState(false)

    const {
        isSyncedWithAbacus,
        currentPlaceId,
    } = value

    const editIsSyncedWithAbacus = useCallback((newVal) => {
        if (isSyncedWithAbacus !== newVal.isSyncedWithAbacus) {
            setIsSyncedWithAbacusEdit(true)
        }
    }, [
        setIsSyncedWithAbacusEdit,
        isSyncedWithAbacus,
    ])

    const {
        classes,
    } = useStyles()

    const setAutocomplete = useCallback((val, isSetOwnField = false) => {
        const updatedValue = isSetOwnField
            ? {
                ...value,
                additional: val,
            }
            : {
                ...value,
                ...val,
            }

        if (JSON.stringify(updatedValue) !== JSON.stringify(value)) {
            dispatch(setValue(updatedValue))
        }
    }, [value])

    const hasPermissions = useHasPermission(RIGHTS_WITH_BILLING_PERMISSION)

    const fields = useFieldsWithClassName(useMemo(() => {
        return getBillingFormFields({
            timeZones,
            isNew,
            isSyncedWithAbacus: value.isSyncedWithAbacus,
            isSyncedWithAbacusEdited,
            setAutocomplete,
            currentPlaceId,
            value,
            isGoogleMapsServiceLoaded,
            hasPermissions,
            getAllowedValues,
        })
    }, [
        isNew,
        isSyncedWithAbacusEdited,
        setAutocomplete,
        currentPlaceId,
        value,
        isGoogleMapsServiceLoaded,
        timeZones,
        hasPermissions,
        getAllowedValues,
    ]), classes)

    const onChangeValue = useCallback((val) => {
        const valueParsed = {
            ...val,
            hasBulkInvoice: val.hasBulkInvoice || false,
            defaultTaxCode: val.defaultTaxCode || defaultTaxCodes,
        }

        if (JSON.stringify(valueParsed) !== JSON.stringify(value)) {
            editIsSyncedWithAbacus(valueParsed)
            dispatch(setValue(valueParsed))
        }
    }, [
        value,
        editIsSyncedWithAbacus,
    ])

    return (
        <FormWithControls
            name="BillingCompany"
            value={value}
            onSuccess={onSuccess}
            onCancel={onCancel}
            fields={fields}
            classNames={{
                gridWrapper: classes.gridWrapperBilling,
            }}
            setExternalValue={onChangeValue}
            disabled={!isNew && !hasPermissions}
            exists={!isNew}
            cancelEnabled={isNew}
            requestParams={request}
            customButtons={customButtons}
        />
    )
}

BillingCompanyForm.defaultProps = defaultProps

export default BillingCompanyForm
