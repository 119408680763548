import React, {
    useCallback,
} from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import Card from 'app/shared-components/Card'
import Button from 'app/shared-components/Button'

import tableConfig from './savedSimulations.config'
import useStyles from './SavedSimulations.style'
import request from '../transportPlaner.request'
import {
    SimulationData,
} from '../Simulation/simulation.types'
import useTransportPlannerRoutes from '../hooks/useTransportPlannerRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const SavedSimulations = () => {
    const TransportPlannerRoutes = useTransportPlannerRoutes()
    const {
        classes,
    } = useStyles()
    const transportPlannerCreate: boolean = useHasPermission(['transport_planner_create'])

    const onRowClick = useCallback(({
        id,
    }: SimulationData): void => {
        TransportPlannerRoutes.Simulation.navigate({
            params: {
                id,
            },
        })
    }, [TransportPlannerRoutes])

    const goToNewSimulation = useCallback((): void => {
        TransportPlannerRoutes.NewSimulation.navigate()
    }, [TransportPlannerRoutes])

    return (
        <div className={classes.simulationWrapper}>
            {transportPlannerCreate
        && (
            <Card
                className={classes.infoMsg}
                contentClass={classes.wrapper}
            >
                <div className={classes.icon}>i</div>
                <span className={classes.msg}>Click here to plan a new transport.</span>
                <Button
                    label="Plan Transport"
                    onClick={goToNewSimulation}
                />
            </Card>
        ) }
            <SkyNetTable
                name="TransportSimulationView"
                customUrl={request.SavedSimulations.url}
                tableControls={tableControls}
                onRowClick={onRowClick}
                tableConfig={tableConfig}
                showSearch
            />
        </div>
    )
}

export default SavedSimulations
