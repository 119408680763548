import React, {
    useMemo,
    useCallback,
} from 'react'

import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import RichTable from 'app/shared-components/RichTableReduxWrapper'
import useRequestTable from 'app/hooks/useRequestTable'
import Card from 'app/shared-components/Card'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'

import useHasPermission from 'app/hooks/useHasPermission'
import useGetRegionalGroupById from '../hooks/useGetRegionalGroupById'

import opportunitiesRequests from './Opportunities.request'
import useStyles from './Opportunities.styles'
import columns from './Opportunities.columns'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

type Props = {
    id: number,
}

const Opportunities = ({
    id,
}: Props) => {
    const {
        data,
    } = useGetRegionalGroupById(id)
    const {
        Opportunities: OpportunitiesRoutes,
    } = useAccountManagementRoutes()

    const {
        classes,
    } = useStyles()
    const {
        onParamsChange,
        loadData: load,
    } = useRequestTable<Props>({
        key: RequestKeys.getOpportunitiesForRegionalGroups,
        params: {
            id,
        },
        config: opportunitiesRequests.filterRequest,
        enabled: Boolean(id),
    })

    const injectLoadFilters = useLoadFilters(
        opportunitiesRequests.filterCountRequest({
            id,
        }),
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [injectLoadFilters])

    const handleAddOpportunity = useCallback(() => {
        OpportunitiesRoutes.Create.navigate({
            options: {
                state: {
                    autofill: {
                        regionalGroup: {
                            id,
                        },
                    },
                },
            },
        })
    }, [
        OpportunitiesRoutes.Create,
        id,
    ])

    const onRowClick = useCallback(({
        id: idOpportunity,
    }: {id: number}) => {
        OpportunitiesRoutes.Overview.navigate({
            params: {
                key: idOpportunity,
            },
            options: {
                state: {
                    filter: {
                        accountId: [data?.account?.id],
                    },
                },
            },
        })
    }, [
        OpportunitiesRoutes.Overview,
        data?.account?.id,
    ])

    const createPermission = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])

    return (
        <Card
            fullHeight
            contentClass={classes.cardContent}
        >
            <div className={classes.header}>
                <Typography variant="h3">
                    Opportunities
                </Typography>
                {createPermission ? (
                    <div className={classes.icon}>
                        <AddIcon
                            data-testid="add-opportunity"
                            className={classes.icon}
                            onMouseDown={handleAddOpportunity}
                        />
                    </div>
                ) : null}
            </div>
            <div
                className={classes.gridCardWrapper}
            >
                <RichTable
                    configName={opportunitiesRequests.domainName}
                    onParamsChange={onParamsChange}
                    name={opportunitiesRequests.domainName}
                    load={load}
                    columns={columnsWithFilters}
                    uniqField="id"
                    className={classes.tableContent}
                    classNames={{
                        contentWrapper: classes.tableContentWrapper,
                    }}
                    onRowClick={onRowClick}
                />
            </div>
        </Card>
    )
}

export default Opportunities
