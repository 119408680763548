import React, {
    useMemo, useState,
} from 'react'

import Card from 'app/shared-components/Card'
import useHasPermission from 'app/hooks/useHasPermission'
import RichTable from 'app/shared-components/RichTableReduxWrapper'
import request from 'app/Apps/AccountManagement/RegionalGroups/regionalGroups.request'
import useLoadFilters from 'app/Apps/DomainObject/hooks/useLoadFilters'
import CollapsableAddRemovePanel from 'app/shared-components/CollapsableAddRemovePanel'
import RegionalGroupCreateForm from './RegionalGroupCreateForm'
import columns from './regionalGroups.columns'
import useStyles from './RegionalGroups.style'
import useGetRegionalGroupsByAccount from './hooks/useGetRegionalGroupsByAccount'
import useAccountManagementRoutes from '../../hooks/useAccountManagementRoutes'

const RegionalGroups = ({
    id,
}: {id: number}) => {
    const {
        classes,
    } = useStyles()
    const {
        RegionalGroups: RegionalGroupsRoutes,
    } = useAccountManagementRoutes()

    const [
        createFormOpened,
        setCreateFormOpened,
    ] = useState<boolean>(false)

    const accountCreate = useHasPermission([
        'account_create_all',
        'account_create_restricted',
    ])
    const {
        onParamsChange,
        loadData,
        invalidate,
    } = useGetRegionalGroupsByAccount()

    const injectLoadFilters = useLoadFilters(
        request.FilterCountRequest(),
    )

    const columnsWithFilters = useMemo(() => {
        return injectLoadFilters(columns)
    }, [injectLoadFilters])

    const onRowClick = ({
        id: idRegionalGroup,
    }: {id: number}) => {
        RegionalGroupsRoutes.Overview.navigate({
            params: {
                key: idRegionalGroup,
            },
        })
    }

    const closeCreateForm = (): void => {
        setCreateFormOpened(false)
    }

    const openCreateForm = (): void => {
        setCreateFormOpened(true)
    }

    const onSuccess = () => {
        closeCreateForm()
        invalidate()
    }

    const defaultFilter = useMemo(() => {
        return {
            accountId: [id],
        }
    }, [id])

    return (
        <Card
            fullHeight
            contentClass={classes.cardContent}
            data-testid="RegionalGroupsTable-card"
        >
            <CollapsableAddRemovePanel
                title="Regional Groups"
                onOpenForm={openCreateForm}
                onCloseForm={closeCreateForm}
                formOpened={createFormOpened}
                disabled={!accountCreate}
            >
                <RegionalGroupCreateForm
                    accountId={id}
                    onSuccess={onSuccess}
                />
            </CollapsableAddRemovePanel>
            <RichTable
                configName="RegionalGroupsTable"
                name={request.domainName}
                load={loadData}
                onParamsChange={onParamsChange}
                columns={columnsWithFilters}
                className={classes.tableContent}
                classNames={{
                    contentWrapper: classes.tableContentWrapper,
                }}
                filter={defaultFilter}
                onRowClick={onRowClick}
            />
        </Card>
    )
}

export default RegionalGroups
