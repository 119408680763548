import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import InternalTransportsOverview from './InternalTransportsOverview'
import orderRequest from '../orders.request'
import request from './internalTransport.request'
import config from './internalTransport.config'
import ContainerSelection from '../Components/ContainerSelection/ContainerSelectionContainer'
import OrderSteps from '../Components/OrderSteps'
import OrderNotificationsTab from '../Components/OrderNotificationsTab'
import useOrderManagementRoutes from '../../hooks/useOrderManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const InternalTransport = () => {
    const {
        InternalOrders: InternalOrdersRoutes,
    } = useOrderManagementRoutes()
    const tabs: SidebarTab[] = [
        {
            url: InternalOrdersRoutes.Overview.route,
            label: 'Overview',
            Component: InternalTransportsOverview,
        },
        {
            url: InternalOrdersRoutes.Notifications.route,
            label: 'Notifications',
            Component: OrderNotificationsTab,
        },
        {
            url: InternalOrdersRoutes.ContainerSelection.route,
            label: 'Containers / Freight',
            Component: ContainerSelection,
        },
        {
            url: InternalOrdersRoutes.OrderSteps.route,
            label: 'Order Steps',
            Component: OrderSteps,
        },
    ]

    return (
        <SkyNetDomain
            name={orderRequest.dataModelName}
            sidebarTabs={tabs}
            uniqField="orderNumber"
        >
            <SkyNetTable
                moduleName={orderRequest.moduleName}
                customUrl={request.all.url}
                tableControls={tableControls}
                tableConfig={config}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default InternalTransport
