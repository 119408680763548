import React from 'react'

import {
    CommonRoutes, useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import LaneGroupCopy from '../LaneGroupCopy/LaneGroupCopy'
import LaneGroupUpdate from '../LaneGroupUpdate'
import usePricingRoutes from '../../hooks/usePricingRoutes'

type Props = {
    id: number,
}

const LaneGroupOverview = ({
    id,
}: Props) => {
    const {
        LaneGroups: LaneGroupsRoutes,
    } = usePricingRoutes()

    return useSkyNetRoutes({
        routes: [
            {
                path: CommonRoutes.SLASH,
                element: <LaneGroupUpdate id={id} />,
            },
            {
                path: LaneGroupsRoutes.Copy.stringParams,
                element: <LaneGroupCopy id={id} />,
            },
        ],
    })
}

export default LaneGroupOverview
