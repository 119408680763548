import {
    createContext,
} from 'react'
import noop from 'lodash/noop'
import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'

export type SkyNetDomainContextValue = {
    name: string,
    updateTableElement: (updatedObj: any) => void,
    addTableElement: (updatedObj: any) => void,
    selectedTab: string,
    setSelectedTab: (value: string) => void,
    selectDomainObject: (id: string | number, to?: string) => void,
    requestKey: RequestKeys,
    getTableRef: (el: HTMLElement) => void,
    creatable: boolean,
    printable: boolean,
    editable: boolean,
    uniqField: string,
}

const SkyNetDomainContext = createContext<SkyNetDomainContextValue>({
    name: '',
    selectedTab: undefined,
    addTableElement: noop,
    updateTableElement: noop,
    setSelectedTab: noop,
    selectDomainObject: noop,
    requestKey: undefined,
    getTableRef: () => {
        return undefined
    },
    creatable: false,
    printable: false,
    editable: false,
    uniqField: undefined,
})

export default SkyNetDomainContext
