import {
    useMemo,
} from 'react'
import {
    useLocation,
} from 'react-router-dom'
import {
    SkyNetTableTabWithLabel,
} from '../types/skyNetTable.types'

export default function useTableTab({
    tabs,
}: {tabs: SkyNetTableTabWithLabel[]}) {
    const {
        pathname,
    } = useLocation()

    return useMemo(() => {
        return tabs.find(({
            url: tabUrl,
        }) => {
            return pathname.match(new RegExp(`/${tabUrl}`, 'g'))
        })
    }, [
        pathname,
        tabs,
    ])
}
