import React from 'react'

import useHasPermission from 'app/hooks/useHasPermission'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    RolesTypes,
} from 'app/utils/hasPermission'

import BillingCompanyOverview from './BillingCompanyOverview'
import BillingCompanyChangeSummary from './BillingCompanyChangeSummary'
import DataUpdate from './BillingCompanyUpdate'
import BillingCompanyCreate from './BillingCompanyCreate'
import tableConfig from './BillingCompanies.config'
import requests from './BillingCompanies.request'
import useContactManagementRoutes from '../hooks/useContactManagementRoutes'

const BILLING_RIGHTS_WITH_CREATE_PERMISSION:RolesTypes[] = [
    'location_bil',
    'location_bil_super',
    'location_sales',
    'location_sales_super',
    'location_airlines',
    'location_airlines_super',
]

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const BillingCompanies = () => {
    const {
        BillingCompanies: BillingCompaniesRoutes,
    } = useContactManagementRoutes()
    const tabs: SidebarTab[] = [
        {
            url: BillingCompaniesRoutes.Overview.route,
            label: 'Overview',
            Component: BillingCompanyOverview,
        },
        {
            url: BillingCompaniesRoutes.Update.route,
            label: 'Data Update',
            Component: DataUpdate,
        },
        {
            url: BillingCompaniesRoutes.ChangeHistory.route,
            label: 'Change History',
            Component: BillingCompanyChangeSummary,
        },
    ]
    const hasBillingCreate = useHasPermission(BILLING_RIGHTS_WITH_CREATE_PERMISSION)

    return (
        <SkyNetDomain
            actionComponents={{
                Create: hasBillingCreate
                    ? BillingCompanyCreate
                    : undefined,
            }}
            name={requests.domainName}
            sidebarTabs={tabs}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                customUrl={requests.filterRequest().url}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default BillingCompanies
