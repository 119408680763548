import React, {
    useCallback,
} from 'react'

import {
    RoutesConfig,
} from 'app/SkyNetRoutes/SkyNetRoutes.types'

import ContactForm from '../ContactForm/ContactForm'
import request from '../Contacts.request'
import useContactManagementRoutes from '../../hooks/useContactManagementRoutes'

const ContactCreate = () => {
    const {
        Contacts: ContactsRoutes,
    } = useContactManagementRoutes()

    const onSuccess = useCallback((contact) => {
        if (contact.isProspect) {
            (ContactsRoutes.Prospect as Record<string, RoutesConfig>).Edit.Overview.navigate({
                params: {
                    key: contact.id,
                },
            })
            return
        }

        ContactsRoutes.Overview.navigate({
            params: {
                key: contact.id,
            },
        })
    }, [ContactsRoutes])

    return (
        <ContactForm
            onSuccess={onSuccess}
            disabled={false}
            request={request.create()}
        />
    )
}

export default ContactCreate
