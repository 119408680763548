import React, {
    useCallback, useMemo, useState,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import useAddCopiedItemWorkaround from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'

import {
    setShowArchived,
} from 'app/shared-components/TableControls/store'

import Button from 'app/shared-components/Button'
import {
    ArchiveButton,
    ArchiveConfirmationDialog,
    useArchive,
} from 'app/shared-components/Archive'
import {
    useAppDispatch,
} from 'app/store/hooks'
import {
    DeliveryServiceType,
} from 'app/Apps/OrderManagement/Orders/orders.types'
import useGetLaneGroup from '../hooks/useGetLaneGroup'
import LaneGroupForm from '../LaneGroupForm'
import LaneGroupRequests from '../LaneGroup.requests'
import LaneGroupHandoverDataUpdate from '../LaneGroupHandoverDataUpdate'
import {
    LaneGroupHandoverData,
} from '../../Pricing.types'
import usePricingRoutes from '../../hooks/usePricingRoutes'

type Props = {
    id: number
}

const LaneGroupUpdate = ({
    id,
}: Props) => {
    const Routes = usePricingRoutes()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetLaneGroup(id)

    const [
        editHandoverData,
        setEditHandoverData,
    ] = useState<LaneGroupHandoverData | null>(null)

    const dispatch = useAppDispatch()

    useAddCopiedItemWorkaround({
        data,
    })

    const onArchiveRestore = useCallback(() => {
        invalidate()
        dispatch(setShowArchived({
            LaneGroup: !data.archived,
        }))
        Routes.LaneGroups.navigate()
    }, [
        invalidate,
        dispatch,
        Routes,
        data?.archived,
    ])

    const {
        handleArchiveRestore,
        openConfirmDialog,
        handleArchiveRestoreConfirm,
    } = useArchive({
        requestParamFn: data?.archived ? LaneGroupRequests.restore
            : LaneGroupRequests.archive,
        notificationId: 'LaneGroupUpdateDto',
        title: 'Lane Group',
        data,
        onSuccess: onArchiveRestore,
    })

    const getRequestParams = () => {
        if (data?.hasImplementedLanes) {
            return LaneGroupRequests.editableData({
                id,
            })
        }
        return LaneGroupRequests.update({
            id,
        })
    }

    const customButtons = useMemo(() => {
        const buttons = [
            <Button
                label="Create Quote"
                key="createquote"
                name="createquote"
                onClick={() => {
                    Routes.ContractBasis.CreateQuote.navigate({
                        params: {
                            key: data?.contractBasisId,
                        },
                        options: {
                            state: {
                                laneGroupId: data?.id,
                                laneGroupNumber: data?.laneGroupNumber,
                            },
                        },
                    })
                }}
            />,
            <Button
                label="Copy"
                key="copy"
                name="copy"
                onClick={() => {
                    Routes.LaneGroups.Copy.navigate({
                        params: {
                            key: id,
                            copy: 'copy',
                        },
                    })
                }}
            />,
            <ArchiveButton
                onClick={handleArchiveRestore}
                archived={data?.archived}
            />,
        ]

        if (data?.hasImplementedLanes
            && data?.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD) {
            buttons.splice(0, 0, <Button
                label="Modify"
                key="modify"
                name="modify"
                onClick={() => {
                    const {
                        transportModeRoad,
                        isFtlPositioningTemperatureControlled,
                        isLtlPositioningTemperatureControlled,
                        id: laneGroupId,
                    } = data

                    setEditHandoverData({
                        transportModeRoad,
                        isFtlPositioningTemperatureControlled,
                        isLtlPositioningTemperatureControlled,
                        id: laneGroupId,
                    })
                }}
            />)
        }

        return buttons
    }, [
        id,
        Routes,
        data,
        handleArchiveRestore,
        setEditHandoverData,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <>
                <LaneGroupForm
                    data={data}
                    onSuccess={invalidate}
                    onCancel={invalidate}
                    requestParam={getRequestParams()}
                    customButtons={customButtons}
                />
                <ArchiveConfirmationDialog
                    title="Lane Group"
                    openConfirmDialog={openConfirmDialog}
                    handleClose={handleArchiveRestoreConfirm}
                    archived={data?.archived}
                />
                {editHandoverData ? (
                    <LaneGroupHandoverDataUpdate
                        onSuccess={invalidate}
                        data={editHandoverData}
                        close={() => {
                            setEditHandoverData(null)
                        }}
                    />
                ) : null}

            </>
        </StatusHandler>
    )
}

export default LaneGroupUpdate
