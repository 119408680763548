import React from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import CountriesRequest from './Countries.request'

import CountryOverview from './CountryOverview'
import tableConfig from './Country.config'
import useMasterdataRoutes from '../hooks/useMasterdataRoutes'

const Countries = () => {
    const {
        Countries: CountriesRoutes,
    } = useMasterdataRoutes()
    const tabs: SidebarTab[] = [{
        url: CountriesRoutes.Overview.route,
        label: 'Overview',
        Component: CountryOverview,
    }]

    const tableControls: TableControlTypes[] = [TableControlTypes.ClearSorting]

    return (
        <SkyNetDomain
            sidebarTabs={tabs}
            name="CountryView"
        >
            <SkyNetTable
                moduleName={CountriesRequest.domainName}
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default Countries
