import {
    useRef,
    useMemo,
    useState,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import {
    RequestKeys,
} from 'app/hooks/useRequest/utils/keys'
import {
    SkyNetDomainContextValue,
} from './SkyNetDomainContext'

type Params = {
    defaultTab: string,
    name: string,
    domainRequestKey: RequestKeys,
    creatable: boolean,
    printable: boolean,
    editable: boolean,
    uniqField?: string,
}

const useCreateSkyNetDomainContext = ({
    defaultTab,
    name,
    domainRequestKey,
    creatable,
    printable,
    editable,
    uniqField,
}: Params) => {
    const navigate = useNavigate()
    const richtableRef = useRef(null)

    const [
        selectedTab,
        setSelectedTab,
    ] = useState<string>(defaultTab)

    return useMemo((): SkyNetDomainContextValue => {
        return {
            name,
            updateTableElement: (updatedObj) => {
                richtableRef.current.update(updatedObj)
            },
            addTableElement: (updatedObj) => {
                richtableRef.current.add(updatedObj)
            },
            selectedTab,
            setSelectedTab,
            uniqField: uniqField ?? 'id',
            selectDomainObject: (id) => { // TODO: rename to 'selectEntityDetailsPage'
                navigate(`${id}`)
            },
            getTableRef: (el: HTMLElement) => {
                richtableRef.current = el
            },
            requestKey: domainRequestKey,
            creatable,
            printable,
            editable,
        }
    }, [
        name,
        selectedTab,
        domainRequestKey,
        uniqField,
        creatable,
        printable,
        editable,
        navigate,
    ])
}

export default useCreateSkyNetDomainContext
