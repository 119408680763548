/* eslint-disable react/jsx-props-no-spreading */
import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import AddEntry from './AddEntry'

const AddEntryContainer = (props: Readonly<{to: string}>) => {
    const {
        to,
    } = props

    const navigate = useNavigate()
    const goToPage = useCallback(() => {
        navigate(to)
    }, [
        navigate,
        to,
    ])

    return (
        <AddEntry
            {...props}
            onClick={goToPage}
        />
    )
}

export default AddEntryContainer
