import {
    tss,
} from 'app/tss'

export default tss.create({
    containerWrapper: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },
    container: {
        height: 'inherit',
        alignContent: 'flex-start',
    },
})
