import React, {
    useMemo,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'

import PreBookings from 'app/Apps/PreBooking'

import {
    useSkyNetRoutes, generateSkyNetRoute,
} from 'app/SkyNetRoutes'
import SkyNetDomainForm from 'app/shared-components/SkyNetDomain/SkyNetDomainForm'
import Pooling from './Pooling'
import DailyOps from './DailyOps'
import OpenBookings from './OpenBookings'
import Lanes from './Lanes'
import CustomerTransport from './Orders/CustomerTransport'
import InternalTransport from './Orders/InternalTransport'
import CreateAirlineLease from './Orders/CustomerTransport/CreateAirlineLease'
import CreateInternalTransportForm from './Orders/InternalTransport/CreateInternalTransportForm'
import CreateDirectLease from './Orders/CustomerTransport/CreateDirectLease'
import useOrderManagementRoutes from './hooks/useOrderManagementRoutes'

export default function OrderManagement() {
    const Routes = useOrderManagementRoutes()
    const navigate = useNavigate()

    const routesConfig = useMemo(() => {
        return {
            [Routes.DailyOps.route]: <DailyOps />,
            [Routes.Pooling.route]: <Pooling />,
            [Routes.OpenBookings.route]: <OpenBookings />,
            [Routes.Lanes.route]: <Lanes />,
            [Routes.CustomerOrders.CreateAirlineLease.route]:
                (
                    <SkyNetDomainForm onClose={() => {
                        navigate(generateSkyNetRoute({
                            domainPath: Routes.CustomerOrders.path,
                        }))
                    }}
                    >
                        <CreateAirlineLease />
                    </SkyNetDomainForm>
                ),
            [Routes.CustomerOrders.CreateDirectLease.route]:
                (
                    <SkyNetDomainForm onClose={() => {
                        navigate(generateSkyNetRoute({
                            domainPath: Routes.CustomerOrders.path,
                        }))
                    }}
                    >
                        <CreateDirectLease />
                    </SkyNetDomainForm>
                ),
            [Routes.InternalOrders.Create.route]:
                (
                    <SkyNetDomainForm onClose={() => {
                        navigate(generateSkyNetRoute({
                            domainPath: Routes.InternalOrders.path,
                        }))
                    }}
                    >
                        <CreateInternalTransportForm />
                    </SkyNetDomainForm>
                ),
            [Routes.CustomerOrders.route]: <CustomerTransport />,
            [Routes.InternalOrders.route]: <InternalTransport />,
            [Routes.PreBookings.route]: <PreBookings />,
        }
    }, [
        Routes,
        navigate,
    ])

    return useSkyNetRoutes({
        routesConfig,
    })
}
