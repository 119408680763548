import {
    TemperatureRange,
    RegionFirstLevel,
} from 'app/types/enums'

export enum RegionalGroupsTabs {
    OPPORTUNITIES = 'opportunities'
}

export type RegionalGroup = {
    id: number,
    changedOn: string,
    regionalGroupNumber: string,
    accountId: number,
    companyName: string,
    account: {
        id: number,
        companyName?: string,
    },
    originRegion: RegionFirstLevel,
    destinationRegion: RegionFirstLevel,
    tempRange: TemperatureRange,
    annualPotentialVolume: number,
    createdBy: string,
    createdOn: string,
    opportunityIndex: number,
    supportingDocuments: {id: number}[],
    additionalInformation: string,
}
