import React from 'react'
import SideBar from 'app/shared-components/SideBar'
import useStyles from './SkyNetDomainForm.styles'

const SkyNetDomainForm = ({
    children,
    onClose,
}: Readonly<{ onClose: () => void, children: JSX.Element }>) => {
    const {
        classes,
    } = useStyles()

    return (
        <SideBar
            onClose={onClose}
        >
            <div className={classes.contentWrapper}>
                <div className={classes.content}>{children}</div>
            </div>
        </SideBar>
    )
}

export default SkyNetDomainForm
