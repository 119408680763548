import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'
import Button from 'app/shared-components/Button'
import useAddCopiedItemWorkaround from 'app/shared-components/SkyNetTable/hooks/useAddCopiedItemWorkaround'

import AlLogisticPricingForm from '../AlLogisticPricingForm'
import useGetAlLogisticPricing from '../hooks/useGetAlLogisticPricing'
import AlLogisticPricingRequests from '../AlLogisticPricing.requests'
import usePricingRoutes from '../../hooks/usePricingRoutes'

const defaultProps = {
    id: undefined,
}

const AlLeasingPricingUpdate = ({
    id,
}: { id?: number }) => {
    const {
        AlLogisticPricing: AlLogisticPricingRoutes,
    } = usePricingRoutes()
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
        invalidate,
    } = useGetAlLogisticPricing(id)

    useAddCopiedItemWorkaround({
        data,
    })

    const customButtons = useMemo(() => {
        return (
            [<Button
                label="Copy"
                key="copy-button"
                onClick={() => {
                    AlLogisticPricingRoutes.Copy.navigate({
                        params: {
                            key: id,
                            copy: 'copy',
                        },
                    })
                }}
            />]
        )
    }, [
        AlLogisticPricingRoutes,
        id,
    ])

    return (
        <StatusHandler
            isSuccess={isDataReady}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <AlLogisticPricingForm
                data={data}
                onSuccess={invalidate}
                onCancel={invalidate}
                requestParam={AlLogisticPricingRequests.update({
                    id,
                })}
                customButtons={customButtons}
            />

        </StatusHandler>
    )
}

AlLeasingPricingUpdate.defaultProps = defaultProps

export default AlLeasingPricingUpdate
