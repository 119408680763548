import React from 'react'

import {
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import useGetLoggerByLoggerNumber from '../hooks/useGetLoggerByLoggerNumber'
import LoggerContainer from '../Logger'

export type LoggerContainerProps = {
    id: string,
    selectedRowId: string,
}
const NAME = 'Logger'

const LoggerOverviewContainer = ({
    id: loggerNumber,
}: LoggerContainerProps) => {
    const {
        data,
        isDataReady,
    } = useGetLoggerByLoggerNumber(loggerNumber)

    return useSkyNetRoutes({
        routes: [...[
            '/:loggerNumber',
            '/',
        ].map((route) => {
            return {
                path: route,
                element: isDataReady ? (
                    <LoggerContainer
                        data={data}
                        name={NAME}
                    />
                ) : null,
            }
        })],
    })
}

export default LoggerOverviewContainer
