import React, {
    useMemo,
} from 'react'

import {
    LocationAreasContext,
    LocationAreasContextType,
} from '../hooks/useLocationAreasContext'

const LocationAreasContextWrapper = ({
    id, children,
}: { id: number, children: JSX.Element }) => {
    const locationAreasContext = useMemo<LocationAreasContextType>(() => {
        return {
            locationId: id,
        }
    }, [id])

    return (
        <LocationAreasContext.Provider value={locationAreasContext}>
            {children}
        </LocationAreasContext.Provider>
    )
}

export default LocationAreasContextWrapper
